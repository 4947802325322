import Chat from '../pages/chat/index';
import Shopping from '../pages/shopping';
import Tools from '../pages/tools';
import Admin from '../pages/admin';

import { PageAds } from '../pages/ads/index';
/**
 * Caso queria que a rota apareça no sidebar, adicionar sidebarName
 */
/*const spa_api_state = (state) => {

};*/

const routes = [
  {
    path: '/home',
    title: 'Página Inicial',
    exact: true,
    sidebarName: 'Home',
    icon: 'poli-icon pi-home-line',
    content: '',
    showPwa: true,
    tag: 'home',
  },
  {
    path: '/chat',
    title: 'Chat',
    sidebarName: 'Chat',
    icon: 'poli-icon pi-message-line',
    content: Chat,
    showPwa: true,
    tag: 'chat',
  },
  {
    path: '/crm/index',
    title: 'CRM',
    sidebarName: 'CRM',
    icon:'poli-icon pi-filter-line',
    content: '',
    tag: 'crm',
    showPwa: true,
  },
  {
    path: '/automation',
    title: 'Automações',
    sidebarName: 'Ações',
    icon: 'poli-icon pi-poli-bot-line',
    content: '',
    tag: 'automation',
    showPwa: true,
  },
  {
    path: '/v2-report',
    title: 'Relatórios',
    sidebarName: 'Relatórios',
    icon: 'poli-icon pi-chart-line',
    content: '',
    showPwa: false,
    tag: 'report',
  },
  {
    path: '/admin',
    title: 'Administração',
    sidebarName: 'Administração',
    subitens: true,
    icon: 'poli-icon pi-company-line',
    content: Admin,
    showPwa: true,
    needAdmin: true,
    tag: 'admin',
  },
  {
    path: '/settings',
    title: 'Configurações',
    sidebarName: 'Configurações',
    icon: 'poli-icon pi-setting-line',
    content: '',
    showPwa: false,
    tag: 'tools',
  },
  {
    path: '/shopping',
    title: 'PoliPay',
    sidebarName: 'PoliPay',
    subitens: true,
    icon: 'poli-icon pi-poli-pay-fill',
    content: Shopping,
    showPwa: true,
    tag: 'pay',
  },
  {
    path: '/tools',
    title: 'Ferramentas',
    sidebarName: 'Ferramentas',
    subitens: true,
    icon: 'poli-icon pi-tool-line',
    content: Tools,
    tag: 'tools',
  },
  {
    path: '/ads',
    title: 'Anuncios',
    sidebarName: 'Anuncios',
    subitens: true,
    icon: 'poli-icon pi-alerts-line',
    content: PageAds,
    tag: 'ads',
  },
];

export default routes;
