import { useWhiteLabel } from '../../../hooks/useWhiteLabel';

function PerformaceOperatorImg() {
  let color1 = '#0000c8';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
    }
    if (whitelabel.cssPathWl.includes('aqbank')) {
      color1 = '#D76F21';
    }
    if (whitelabel.cssPathWl.includes('usecredituz')) {
      color1 = '#000000';
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      className="img-fluid"
      height={392}
      width={392}
    >
      <g id="freepik--background-complete--inject-220">
        <rect y="382.4" width="500" height="0.25" fill="#ebebeb"></rect>
        <rect
          x="416.78"
          y="398.49"
          width="33.12"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="322.53"
          y="401.21"
          width="8.69"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="396.59"
          y="389.21"
          width="19.19"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="52.46"
          y="390.89"
          width="43.19"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="104.56"
          y="390.89"
          width="6.33"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <rect
          x="131.47"
          y="395.11"
          width="93.68"
          height="0.25"
          fill="#ebebeb"
        ></rect>
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          fill="#ebebeb"
        ></path>
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          fill="#ebebeb"
        ></path>
        <polygon
          points="118.94 318.39 118.94 332.59 112.94 336.95 140.94 336.95 132 333.63 132 319.38 118.94 318.39"
          fill="#e0e0e0"
        ></polygon>
        <path
          d="M69.47,336.54v44.73h125V336.54Zm122.75,17.52H94.38V339.44h97.84Z"
          fill="#e0e0e0"
        ></path>
        <path
          d="M91.37,336.54v44.73h103.1V336.54Zm100.85,17.52H94.38V339.44h97.84Z"
          fill="#ebebeb"
        ></path>
        <rect
          x="172.05"
          y="339.44"
          width="20.17"
          height="14.62"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="94.38"
          y="346.75"
          width="24.95"
          height="7.31"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="121.17"
          y="349.71"
          width="18.78"
          height="4.34"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="144.29"
          y="345.18"
          width="17.89"
          height="8.88"
          fill="#e0e0e0"
        ></rect>
        <polygon
          points="112.11 357.24 111.58 358.99 104.4 358.99 103.86 357.24 94.38 357.24 94.38 376.65 125.85 376.65 125.85 357.24 112.11 357.24"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="149.51 357.24 148.97 358.99 141.79 358.99 141.25 357.24 127.52 357.24 127.52 376.65 158.99 376.65 158.99 357.24 149.51 357.24"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="178.39 357.24 177.86 358.99 170.68 358.99 170.14 357.24 160.66 357.24 160.66 376.65 192.13 376.65 192.13 357.24 178.39 357.24"
          fill="#e0e0e0"
        ></polygon>
        <rect
          x="79.67"
          y="266.42"
          width="100.05"
          height="60.45"
          rx="3.53"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="82.02"
          y="266.42"
          width="100.05"
          height="60.45"
          rx="3.53"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="104.76"
          y="249.56"
          width="54.58"
          height="94.17"
          transform="translate(428.69 164.6) rotate(90)"
          fill="#f0f0f0"
        ></rect>
        <path
          d="M310.87,261.18h89.54A17.58,17.58,0,0,1,418,278.76v69.92H293.29V278.76A17.58,17.58,0,0,1,310.87,261.18Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M266.82,313h16a10.45,10.45,0,0,1,10.45,10.45v57.82H256.37V323.45A10.45,10.45,0,0,1,266.82,313Z"
          fill="#e0e0e0"
        ></path>
        <path
          d="M266.82,313h.33a10.45,10.45,0,0,1,10.45,10.45v57.82H256.37V323.45A10.45,10.45,0,0,1,266.82,313Z"
          fill="#ebebeb"
        ></path>
        <rect
          x="282.07"
          y="354.71"
          width="122.88"
          height="26.56"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="271.28"
          y="330.42"
          width="139.75"
          height="27.87"
          rx="10.62"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="374.89"
          y="330.42"
          width="35.95"
          height="27.87"
          rx="10.62"
          fill="#e0e0e0;isolation:isolate"
        ></rect>
        <path
          d="M401.79,313h16a10.45,10.45,0,0,1,10.45,10.45v57.82H391.34V323.45A10.45,10.45,0,0,1,401.79,313Z"
          fill="#e0e0e0"
        ></path>
        <path
          d="M401.79,313h.34a10.45,10.45,0,0,1,10.45,10.45v57.82H391.34V323.45A10.45,10.45,0,0,1,401.79,313Z"
          fill="#f0f0f0"
        ></path>
        <rect
          x="306.06"
          y="106.38"
          width="118.63"
          height="122.58"
          transform="translate(730.76 335.34) rotate(180)"
          fill="#ebebeb"
        ></rect>
        <rect
          x="301.92"
          y="106.38"
          width="118.63"
          height="122.58"
          transform="translate(722.47 335.34) rotate(180)"
          fill="#f0f0f0"
        ></rect>
        <rect
          x="305.06"
          y="109.28"
          width="112.5"
          height="116.83"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="307.87"
          y="109.28"
          width="109.69"
          height="116.83"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="361.58"
          y="109.28"
          width="2.23"
          height="116.83"
          transform="translate(725.39 335.4) rotate(180)"
          fill="#ebebeb"
        ></rect>
        <polygon
          points="348.8 109.28 317.54 226.11 314.54 226.11 345.8 109.28 348.8 109.28"
          fill="#fff"
        ></polygon>
        <rect
          x="360.63"
          y="107.48"
          width="1.91"
          height="120.87"
          transform="translate(723.17 335.83) rotate(180)"
          fill="#f0f0f0"
        ></rect>
        <polygon
          points="337.45 109.28 307.87 219.84 307.87 159.07 321.19 109.28 337.45 109.28"
          fill="#fff"
        ></polygon>
        <polygon
          points="417.56 119.88 417.56 131.1 392.13 226.11 389.13 226.11 417.56 119.88"
          fill="#fff"
        ></polygon>
        <polygon
          points="417.56 142.53 417.56 203.3 411.46 226.11 395.2 226.11 417.56 142.53"
          fill="#fff"
        ></polygon>
        <rect
          x="97.66"
          y="113.36"
          width="71.83"
          height="87.4"
          transform="translate(267.15 314.13) rotate(180)"
          fill="#e6e6e6"
        ></rect>
        <polygon
          points="114.1 179.12 151.84 142.02 111.01 135.28 114.1 179.12"
          fill="#e0e0e0"
        ></polygon>
        <polygon
          points="150.93 170.32 152.8 141.87 124.38 148.85 122.32 170.62 150.93 170.32"
          fill="#f0f0f0"
        ></polygon>
        <circle
          cx="113.75"
          cy="130.29"
          r="11.47"
          transform="translate(-58.81 118.6) rotate(-45)"
          fill="#fafafa"
        ></circle>
        <path
          d="M147.22,180.46a5.57,5.57,0,1,1,5.56,5.57A5.56,5.56,0,0,1,147.22,180.46Z"
          fill="#e0e0e0"
        ></path>
        <polygon
          points="151.46 190.24 135.4 180.46 148.9 169.34 151.46 190.24"
          fill="#f5f5f5"
        ></polygon>
        <rect
          x="129.33"
          y="124.48"
          width="3.16"
          height="69.79"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="158.66"
          y="124.48"
          width="4.09"
          height="66.7"
          fill="#ebebeb"
        ></rect>
        <rect
          x="104.87"
          y="170.62"
          width="20.88"
          height="20.88"
          fill="#ebebeb"
        ></rect>
      </g>
      <g id="freepik--Shadow--inject-220">
        <ellipse
          id="freepik--path--inject-220"
          cx="250"
          cy="416.24"
          rx="193.89"
          ry="11.32"
          fill="#f5f5f5"
        ></ellipse>
      </g>
      <g id="freepik--Device--inject-220">
        <path
          d="M199.36,236H407.72a4.27,4.27,0,0,1,4.27,4.27V391a0,0,0,0,1,0,0H195.09a0,0,0,0,1,0,0V240.31A4.27,4.27,0,0,1,199.36,236Z"
          fill="#263238"
        ></path>
        <rect
          x="199.36"
          y="245.61"
          width="208.36"
          height="133.17"
          fill={color1}
        ></rect>
        <circle
          cx="301.64"
          cy="241.2"
          r="2.08"
          style={{ fill: '#fff', opacity: '0.2', isolation: 'isolate' }}
        ></circle>
        <circle
          cx="305.95"
          cy="241.2"
          r="1.56"
          style={{ fill: '#fff', opacity: '0.2', isolation: 'isolate' }}
        ></circle>
        <path
          d="M304.39,381.51a2.66,2.66,0,0,0-.85-.15,2.73,2.73,0,1,0,2.73,2.73.68.68,0,0,0,0-.15A2.72,2.72,0,0,1,304.39,381.51Z"
          style={{ fill: '#fff', opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M428.16,388.88v2a2.9,2.9,0,0,1-2.06,2.76L411,398.06a20.16,20.16,0,0,1-5.78.84H201.88a20.14,20.14,0,0,1-5.77-.84L181,393.6a2.9,2.9,0,0,1-2.07-2.76v-2Z"
          fill="#263238"
        ></path>
        <path
          d="M428.16,388.88v2a2.9,2.9,0,0,1-2.06,2.76L411,398.06a20.16,20.16,0,0,1-5.78.84H201.88a20.14,20.14,0,0,1-5.77-.84L181,393.6a2.9,2.9,0,0,1-2.07-2.76v-2Z"
          style={{ fill: '#fff', opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M428.16,388.88v2a2.85,2.85,0,0,1-.41,1.47H179.34a2.85,2.85,0,0,1-.41-1.47v-2Z"
          style={{ fill: '#fff', opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M321.08,388.88v.86a.85.85,0,0,1-.85.85H286.87a.85.85,0,0,1-.86-.85v-.86Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M407.72,245.61S378.91,360,199.36,378.78V245.61Z"
          style={{ fill: '#fff', opacity: '0.1', isolation: 'isolate' }}
        ></path>
      </g>
      <g id="freepik--window-3--inject-220">
        <path
          d="M388,193.47V318.73l-20.86,20.86H284.66a3.22,3.22,0,0,1-3.21-3.22V193.47a3.22,3.22,0,0,1,3.21-3.22h100.1A3.22,3.22,0,0,1,388,193.47Z"
          fill={color1}
        ></path>
        <path
          d="M388,198.94V318.73l-20.86,20.86H284.66a3.22,3.22,0,0,1-3.21-3.22V198.94Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M385.2,194.23a1.56,1.56,0,1,1-1.56-1.55A1.55,1.55,0,0,1,385.2,194.23Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M379.26,194.23a1.55,1.55,0,1,1-1.55-1.55A1.54,1.54,0,0,1,379.26,194.23Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M373.33,194.23a1.56,1.56,0,1,1-1.55-1.55A1.55,1.55,0,0,1,373.33,194.23Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M346.2,208.59H289.1a1,1,0,0,1,0-2h57.1a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M330.06,214.06h-41a1,1,0,0,1,0-2h41a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,298.52h-73a1,1,0,1,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M362.12,320.37h-73a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,304H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,309.45H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,314.91H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <polygon
          points="387.98 318.73 367.12 339.59 367.12 318.73 387.98 318.73"
          style={{ fill: color1, opacity: '0.5' }}
        ></polygon>
        <rect
          x="382.09"
          y="198.94"
          width="5.89"
          height="119.79"
          style={{ opacity: '0.4', isolation: 'isolate' }}
        ></rect>
        <path
          d="M385,261.53h0a2.17,2.17,0,0,1-2.17-2.17V247.12A2.17,2.17,0,0,1,385,245h0a2.17,2.17,0,0,1,2.17,2.17v12.24A2.17,2.17,0,0,1,385,261.53Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,268.52h-73a1,1,0,1,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M362.12,290.37h-73a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,274H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,279.45H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M374.33,284.91H289.1a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <rect
          x="288.84"
          y="220.57"
          width="85.48"
          height="31.9"
          fill={color1}
        ></rect>
        <polygon
          points="288.84 247.35 291.17 247.35 291.17 241.55 293.85 241.55 293.85 230.1 297.59 230.1 297.59 237.15 302.73 237.15 302.73 246.52 308.86 246.52 308.86 239.99 313.93 239.99 317.28 236.63 317.28 228.86 319.33 228.86 319.33 239.99 324.49 239.99 324.49 243.95 329.83 243.95 329.83 223.75 331.31 222.27 331.31 227.81 334.72 227.81 334.72 230.3 337.99 233.58 337.99 239.49 342.69 239.49 342.69 234.81 354.95 234.81 354.95 231.32 357.72 231.32 357.72 225.22 358.71 225.22 358.71 234.81 360.27 234.81 363.8 231.28 367.12 231.28 367.12 228.09 368.8 228.09 368.8 231.32 370.97 231.32 370.97 244.54 373.33 244.54 373.33 237.94 374.33 238 374.33 220.57 288.84 220.57 288.84 247.35"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></polygon>
        <path
          d="M374.33,257.52h-23a1,1,0,1,1,0-2h23a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M322.7,190.25V293.77L307,309.51H281.45v-116a3.22,3.22,0,0,1,3.21-3.22Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M385,261.53h0a2.17,2.17,0,0,1-2.17-2.17V247.12A2.17,2.17,0,0,1,385,245h0a2.17,2.17,0,0,1,2.17,2.17v12.24A2.17,2.17,0,0,1,385,261.53Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
      </g>
      <g id="freepik--window-1--inject-220">
        <path
          d="M261.74,209.08V334.34L240.88,355.2H158.42a3.22,3.22,0,0,1-3.21-3.22V209.08a3.22,3.22,0,0,1,3.21-3.22h100.1A3.22,3.22,0,0,1,261.74,209.08Z"
          fill={color1}
        ></path>
        <path
          d="M261.74,214.56V334.35l-20.86,20.86H158.42a3.22,3.22,0,0,1-3.21-3.22V214.56Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M259,209.85a1.56,1.56,0,1,1-1.56-1.55A1.55,1.55,0,0,1,259,209.85Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M253,209.85a1.56,1.56,0,1,1-1.55-1.55A1.54,1.54,0,0,1,253,209.85Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M247.09,209.85a1.56,1.56,0,1,1-1.56-1.55A1.55,1.55,0,0,1,247.09,209.85Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M180.6,224.75H162.86a1,1,0,0,1,0-2H180.6a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M189,230.21h-26.1a1,1,0,0,1,0-2H189a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M180.89,235.67h-18a1,1,0,0,1,0-2h18a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M248.08,325.14h-73a1,1,0,0,1,0-2h73a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M208.08,330.6H162.86a1,1,0,0,1,0-2h45.22a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <polygon
          points="261.74 334.35 240.88 355.21 240.88 334.35 261.74 334.35"
          style={{ fill: color1, opacity: '0.5' }}
        ></polygon>
        <rect
          x="255.85"
          y="214.56"
          width="5.89"
          height="119.79"
          style={{ opacity: '0.4', isolation: 'isolate' }}
        ></rect>
        <path
          d="M258.79,326.15h0a2.17,2.17,0,0,1-2.17-2.17V311.74a2.17,2.17,0,0,1,2.17-2.17h0a2.17,2.17,0,0,1,2.17,2.17V324A2.17,2.17,0,0,1,258.79,326.15Z"
          fill={color1}
        ></path>
        <path
          d="M248.08,224.75H234.35a1,1,0,0,1,0-2h13.73a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M248.08,230.21H241a1,1,0,0,1,0-2h7.1a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M248.08,250.14h-73a1,1,0,0,1,0-2h73a1,1,0,1,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M248.08,255.6H162.86a1,1,0,0,1,0-2h85.22a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M198.08,261.06H162.86a1,1,0,0,1,0-2h35.22a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <rect
          x="177"
          y="268.31"
          width="33.58"
          height="9"
          style={{ fill: color1, opacity: '0.7000000000000001' }}
        ></rect>
        <rect
          x="177"
          y="279.31"
          width="15.21"
          height="33.23"
          style={{ fill: color1, opacity: '0.30000000000000004' }}
        ></rect>
        <rect
          x="212.57"
          y="279.31"
          width="17.47"
          height="33.23"
          style={{ fill: color1, opacity: '0.30000000000000004' }}
        ></rect>
        <rect
          x="212.57"
          y="268.31"
          width="35.51"
          height="9"
          style={{ fill: color1, opacity: '0.7000000000000001' }}
        ></rect>
        <rect
          x="232.05"
          y="279.31"
          width="16.04"
          height="33.23"
          style={{ fill: color1, opacity: '0.30000000000000004' }}
        ></rect>
        <rect
          x="162.86"
          y="279.31"
          width="12.14"
          height="33.23"
          style={{ fill: color1, opacity: '0.5' }}
        ></rect>
        <rect
          x="162.86"
          y="268.31"
          width="12.14"
          height="9"
          fill={color1}
        ></rect>
        <rect
          x="194.21"
          y="279.31"
          width="16.36"
          height="33.23"
          style={{ fill: color1, opacity: '0.30000000000000004' }}
        ></rect>
        <path
          d="M258.79,326.15h0a2.17,2.17,0,0,1-2.17-2.17V311.74a2.17,2.17,0,0,1,2.17-2.17h0a2.17,2.17,0,0,1,2.17,2.17V324A2.17,2.17,0,0,1,258.79,326.15Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
        <path
          d="M261.74,209.08v104.2H201.41a3.22,3.22,0,0,1-3.21-3.22V205.86h60.32A3.22,3.22,0,0,1,261.74,209.08Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
      </g>
      <g id="freepik--window-2--inject-220">
        <path
          d="M314.71,152.3V277.56l-20.86,20.86H211.39a3.22,3.22,0,0,1-3.21-3.22V152.3a3.22,3.22,0,0,1,3.21-3.22h100.1A3.22,3.22,0,0,1,314.71,152.3Z"
          fill={color1}
        ></path>
        <path
          d="M314.71,157.77V277.56l-20.86,20.86H211.39a3.22,3.22,0,0,1-3.21-3.22V157.77Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <circle
          cx="310.37"
          cy="153.06"
          r="1.55"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></circle>
        <circle
          cx="304.43"
          cy="153.06"
          r="1.55"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></circle>
        <path
          d="M300.05,153.06a1.56,1.56,0,1,1-1.55-1.55A1.54,1.54,0,0,1,300.05,153.06Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M268.62,168H248.25a1,1,0,0,1,0-2h20.37a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M277,173.42h-37.1a1,1,0,0,1,0-2H277a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M268.92,178.89H248a1,1,0,0,1,0-2h21a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,193.35H228a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M288.84,215.2h-73a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,198.81H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,204.28H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,209.74H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,248.35H228a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M288.84,270.2h-73a1,1,0,0,1,0-2h73a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,253.81H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,259.28H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M301.05,264.74H215.82a1,1,0,0,1,0-2h85.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <rect
          x="290.82"
          y="165.76"
          width="10.23"
          height="13.62"
          fill={color1}
        ></rect>
        <circle cx="221.5" cy="171.43" r="5.67" fill={color1}></circle>
        <path
          d="M215.5,222.35a1.2,1.2,0,0,0,2.39,0,1.2,1.2,0,1,0-2.39,0Z"
          fill={color1}
        ></path>
        <path
          d="M215.5,227.81a1.2,1.2,0,1,0,1.19-1.19A1.19,1.19,0,0,0,215.5,227.81Z"
          fill={color1}
        ></path>
        <path
          d="M215.5,233.28a1.2,1.2,0,0,0,2.39,0,1.2,1.2,0,1,0-2.39,0Z"
          fill={color1}
        ></path>
        <path
          d="M215.5,238.74a1.2,1.2,0,0,0,2.39,0,1.2,1.2,0,1,0-2.39,0Z"
          fill={color1}
        ></path>
        <path
          d="M233.84,223.35h-13a1,1,0,0,1,0-2h13a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M246.05,228.81H220.82a1,1,0,0,1,0-2h25.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M231.05,234.28H220.82a1,1,0,0,1,0-2h10.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <path
          d="M242.05,239.74H220.82a1,1,0,0,1,0-2h21.23a1,1,0,0,1,0,2Z"
          fill={color1}
        ></path>
        <polygon
          points="314.71 277.56 293.85 298.42 293.85 277.56 314.71 277.56"
          style={{ fill: color1, opacity: '0.5' }}
        ></polygon>
        <polygon
          points="301.05 165.76 290.82 179.38 301.05 179.38 301.05 165.76"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></polygon>
        <rect
          x="218.18"
          y="168.12"
          width="6.63"
          height="6.63"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></rect>
        <rect
          x="308.81"
          y="157.77"
          width="5.89"
          height="119.79"
          style={{ opacity: '0.4', isolation: 'isolate' }}
        ></rect>
        <path
          d="M311.76,180.36h0a2.17,2.17,0,0,1-2.17-2.17V166a2.17,2.17,0,0,1,2.17-2.17h0a2.17,2.17,0,0,1,2.17,2.17v12.24A2.17,2.17,0,0,1,311.76,180.36Z"
          fill={color1}
        ></path>
        <path
          d="M311.76,180.36h0a2.17,2.17,0,0,1-2.17-2.17V166a2.17,2.17,0,0,1,2.17-2.17h0a2.17,2.17,0,0,1,2.17,2.17v12.24A2.17,2.17,0,0,1,311.76,180.36Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
      </g>
      <g id="freepik--speech-bubble--inject-220">
        <path
          d="M83.83,208.93h34.92a5.07,5.07,0,0,1,5.06,5.07v33.94a5.06,5.06,0,0,1-5.06,5.06H104.57l1.53,10.23-7-10.23H83.83a5.05,5.05,0,0,1-5.06-5.06V214A5.06,5.06,0,0,1,83.83,208.93Z"
          fill={color1}
        ></path>
        <path
          d="M83.83,208.93h34.92a5.07,5.07,0,0,1,5.06,5.07v33.94a5.06,5.06,0,0,1-5.06,5.06H104.57l1.53,10.23-7-10.23H83.83a5.05,5.05,0,0,1-5.06-5.06V214A5.06,5.06,0,0,1,83.83,208.93Z"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></path>
        <path
          d="M98.41,223.91v2.71l1.4.13a7.08,7.08,0,0,0,1,2.5l-.89,1.08,1.91,1.91,1.09-.89a7.2,7.2,0,0,0,2.5,1l.13,1.39h2.7l.14-1.39a7.37,7.37,0,0,0,2.5-1l1.08.89,1.91-1.91-.89-1.08a7.28,7.28,0,0,0,1-2.5l1.39-.13v-2.71l-1.39-.13a7.28,7.28,0,0,0-1-2.5l.89-1.08L112,218.28l-1.08.9a7.37,7.37,0,0,0-2.5-1l-.14-1.4h-2.7l-.13,1.4a7.2,7.2,0,0,0-2.5,1l-1.09-.9L100,220.2l.89,1.08a7.08,7.08,0,0,0-1,2.5Zm8.52-2.31a3.67,3.67,0,1,1-3.66,3.66A3.67,3.67,0,0,1,106.93,221.6Z"
          fill="#f5f5f5"
        ></path>
        <path
          d="M100.16,243.66a5.85,5.85,0,0,0,2-.81l.85.7,1.5-1.5-.7-.85a5.85,5.85,0,0,0,.81-2l1.09-.1V237l-1.09-.1a5.68,5.68,0,0,0-.81-2l.7-.85-1.5-1.5-.85.7a5.47,5.47,0,0,0-2-.81l-.1-1.1H97.94l-.11,1.1a5.47,5.47,0,0,0-2,.81l-.84-.7-1.5,1.5.7.85a5.51,5.51,0,0,0-.82,2l-1.09.1v2.12l1.09.1a5.67,5.67,0,0,0,.82,2l-.7.85,1.5,1.5.84-.7a5.85,5.85,0,0,0,2,.81l.11,1.09h2.12Zm-4-5.58A2.88,2.88,0,1,1,99,241,2.87,2.87,0,0,1,96.12,238.08Z"
          fill="#f5f5f5"
        ></path>
        <path
          d="M94.64,231.7l.59.71,1.47-1-.45-.81a4.6,4.6,0,0,0,1-1.49l.92.09.33-1.75-.89-.26a4.83,4.83,0,0,0-.36-1.74l.71-.59-1-1.47-.81.44a4.57,4.57,0,0,0-1.49-1l.09-.92L93,221.6l-.26.88a4.63,4.63,0,0,0-1.74.37l-.59-.71-1.47,1,.44.81a4.79,4.79,0,0,0-1,1.49l-.92-.08-.33,1.75.88.25a4.73,4.73,0,0,0,.37,1.75l-.71.59,1,1.47.81-.45a4.82,4.82,0,0,0,1.49,1l-.08.92,1.75.33.25-.89A4.93,4.93,0,0,0,94.64,231.7Zm-4.21-4.87a2.41,2.41,0,1,1,4.74.89,2.41,2.41,0,0,1-4.74-.89Z"
          fill="#f5f5f5"
        ></path>
      </g>
      <g id="freepik--Character--inject-220">
        <path
          d="M141.36,333.12s2.46,22.14,3.26,24.78,12.07,16.74,12.07,16.74l-3.19,1.67s-12-12.34-13.91-16-4.77-22.76-4.77-22.76Z"
          fill="#ebb376"
        ></path>
        <path
          d="M139.11,323.26c4.15,2.2,5.51,32.93,5.51,32.93l2.1,3.23L140.34,364a57.34,57.34,0,0,1-4-7.92c-1.27-3.42-4.28-20.3-4.28-20.3Z"
          fill="#263238"
        ></path>
        <path
          d="M139.11,323.26c4.15,2.2,5.51,32.93,5.51,32.93l2.1,3.23L140.34,364a57.34,57.34,0,0,1-4-7.92c-1.27-3.42-4.28-20.3-4.28-20.3Z"
          style={{ fill: '#fff', opacity: '0.1', isolation: 'isolate' }}
        ></path>
        <polygon
          points="139.4 363.97 146.72 358.17 148.29 361.46 141.54 366.27 139.4 363.97"
          fill={color1}
        ></polygon>
        <polygon
          points="139.4 363.97 146.72 358.17 148.29 361.46 141.54 366.27 139.4 363.97"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></polygon>
        <path
          d="M122.75,362.88l7.48,11.83c14.83-3.45,62.36-10.7,68.19,5.61,7.26,20.26-45.17,36.42-74.18,37.41S71.94,402.2,90.29,359.9Z"
          fill={color1}
        ></path>
        <path
          d="M122.75,362.88l7.48,11.83c14.83-3.45,62.36-10.7,68.19,5.61,7.26,20.26-45.17,36.42-74.18,37.41S71.94,402.2,90.29,359.9Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M190,409a.2.2,0,0,1-.21,0,.15.15,0,0,1-.05-.18c.09-.33,1.05-3.12,2-3.41a.6.6,0,0,1,.58.17,1,1,0,0,1,.35.94,2.63,2.63,0,0,1-.91,1.31A11.21,11.21,0,0,1,190,409Zm.25-.54a4.18,4.18,0,0,0,2.07-2,.64.64,0,0,0-.25-.64.22.22,0,0,0-.23-.07,1,1,0,0,0-.22.13A8.17,8.17,0,0,0,190.27,408.43Z"
          fill={color1}
        ></path>
        <path
          d="M190,409a.14.14,0,0,1-.18-.05c-.09-.08-1.43-1.77-1.21-2.91a1.06,1.06,0,0,1,.46-.7.62.62,0,0,1,.77-.07c.71.49.62,2.76.28,3.61l0,.09Zm-.76-3.31a.71.71,0,0,0-.23.4,3.93,3.93,0,0,0,.89,2.33c.22-1,.15-2.55-.26-2.84,0,0-.13-.09-.34.06l-.06.05Z"
          fill={color1}
        ></path>
        <path
          d="M139,413.72a.18.18,0,0,1,.19-.09.15.15,0,0,1,.13.13c.07.33.58,3.24-.13,4a.56.56,0,0,1-.58.13,1,1,0,0,1-.76-.65,2.59,2.59,0,0,1,.16-1.58A11.05,11.05,0,0,1,139,413.72Zm0,.59a4.18,4.18,0,0,0-.85,2.74.64.64,0,0,0,.53.44.19.19,0,0,0,.23-.05.61.61,0,0,0,.13-.22A8.13,8.13,0,0,0,139.06,414.31Z"
          fill={color1}
        ></path>
        <polygon
          points="158.01 399.04 146.07 403.29 140.34 405.33 141.81 411.61 147.36 410.42 160.92 407.53 158.01 399.04"
          fill="#ebb376"
        ></polygon>
        <path
          d="M143.37,411.9l-1-7.4a.74.74,0,0,0-.86-.63l-3.65.61s-5,.47-6,1.74c-2.89,3.73-1.09,20.15.84,20.73.7.21,1.44-.75,1.75-1.42,1-2.12,2.69-7.65,3.52-9.41,1-2.05,3.64-3.05,4.84-3.41A.74.74,0,0,0,143.37,411.9Z"
          fill="#263238"
        ></path>
        <path
          d="M139.05,413.66a.17.17,0,0,1,.18,0c.12,0,2.11.86,2.47,2a1.11,1.11,0,0,1-.07.84.63.63,0,0,1-.64.44c-.85-.09-1.88-2.12-2-3l0-.09A.18.18,0,0,1,139.05,413.66Zm2.27,2.54a.76.76,0,0,0,0-.47c-.23-.7-1.34-1.32-1.91-1.6.28.95,1.11,2.31,1.61,2.36,0,0,.15,0,.26-.21l0-.08Z"
          fill={color1}
        ></path>
        <polygon
          points="158.01 399.04 146.07 403.29 147.36 410.42 160.92 407.53 158.01 399.04"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></polygon>
        <polygon
          points="149.47 411.5 152.91 411.74 150.61 399.93 146.72 402.09 149.47 411.5"
          fill={color1}
        ></polygon>
        <polygon
          points="149.47 411.5 152.91 411.74 150.61 399.93 146.72 402.09 149.47 411.5"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></polygon>
        <path
          d="M99.68,365.6l17.88,19.83s19.77-.44,43.77,11.29c0,0,25.75-10.64,28.12-19.48,3.1-11.58-59.22-2.53-59.22-2.53Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M183.54,379.6v2.94c0,.75-.89,1.06-1.63,1.06H123.2c-.75,0-1.66-.31-1.66-1.06V379.6Z"
          fill="#263238"
        ></path>
        <path
          d="M183.54,379.6v2.94c0,.75-.89,1.06-1.63,1.06H123.2c-.75,0-1.66-.31-1.66-1.06V379.6Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
        <path
          d="M183.54,379.6v2.94c0,.75-.89,1.06-1.63,1.06H143.19c-.75,0-1.65-.31-1.65-1.06V379.6Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M193.36,349.1l-7,31.7a2.39,2.39,0,0,1-2.27,1.8H145.93a1.53,1.53,0,0,1-1.43-2l7-31.46a2.17,2.17,0,0,1,2.26-1.55h38.18C193,347.6,193.59,348.07,193.36,349.1Z"
          fill="#263238"
        ></path>
        <path
          d="M193.36,349.1l-7,31.7a2.39,2.39,0,0,1-2.27,1.8H147.23a1.55,1.55,0,0,1-1.45-2l7-31.46A2.19,2.19,0,0,1,155,347.6h36.88C193,347.6,193.59,348.07,193.36,349.1Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
        <path
          d="M174.54,365.21h0c0,2.72-2.91,4.92-5,4.92s-3.4-2.2-2.79-4.92,2.73-4.83,4.82-4.91c-.61,2.72.69,4.91,2.82,4.91A1,1,0,0,0,174.54,365.21Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <polygon
          points="166.3 412.62 178.8 414.68 184.8 415.67 186.55 409.47 181.12 407.81 167.86 403.78 166.3 412.62"
          fill="#ebb376"
        ></polygon>
        <path
          d="M185.33,408.45l-2.7,7a.73.73,0,0,0,.44,1l3.49,1.23s4.57,2,6.05,1.36c4.33-1.86,10.7-17.11,9.3-18.54-.51-.53-1.63,0-2.22.39-1.88,1.38-6.06,5.4-7.63,6.53-1.85,1.32-4.67.91-5.89.65A.72.72,0,0,0,185.33,408.45Z"
          fill="#263238"
        ></path>
        <polygon
          points="166.3 412.62 178.8 414.68 181.12 407.81 167.86 403.78 166.3 412.62"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></polygon>
        <path
          d="M179,406.52s-51-28.66-68.56-27.84-20.13,16.32-12.87,26,20.66,12.06,20.66,12.06-9.13-12.89-7.81-16.52,6.48,1.44,6.48,1.44S148.94,412,177,414.42Z"
          fill={color1}
        ></path>
        <polygon
          points="179.79 405.73 176.91 403.79 173.2 415.18 177.65 415.18 179.79 405.73"
          fill={color1}
        ></polygon>
        <polygon
          points="179.79 405.73 176.91 403.79 173.2 415.18 177.65 415.18 179.79 405.73"
          style={{
            fill: '#fff',
            opacity: '0.6000000000000001',
            isolation: 'isolate',
          }}
        ></polygon>
        <path
          d="M90.29,359.9l32.47,3s5.09-10.54,9-14.65,7.36-25,7.36-25a69.34,69.34,0,0,0-8.14-3.32,80.68,80.68,0,0,0-24.84-4.18H106c-2.42,0-3.82.12-3.82.12a6.9,6.9,0,0,0-2.31,2.63c-3.48,6-6.25,20-7.89,30.05C90.87,355.05,90.29,359.9,90.29,359.9Z"
          fill="#263238"
        ></path>
        <path
          d="M91.93,348.56c7.16-3.28,16.93-8.83,16.42-14.46-.55-6.18-5.56-12.4-8.53-15.59C96.34,324.51,93.57,338.51,91.93,348.56Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M107.72,315.79s3.7,12.18,13.82,13.5,11.8-8.5,11.8-8.5S120.31,313.64,107.72,315.79Z"
          fill="#263238"
        ></path>
        <path
          d="M107.72,315.79s3.7,12.18,13.82,13.5,11.8-8.5,11.8-8.5S120.31,313.64,107.72,315.79Z"
          style={{ fill: '#fff', opacity: '0.1', isolation: 'isolate' }}
        ></path>
        <path
          d="M109.84,316.13s4.77,11.28,13.37,11.28,8.87-6.33,8.87-6.33S123.48,315.44,109.84,316.13Z"
          fill={color1}
        ></path>
        <path
          d="M109.84,316.13s4.77,11.28,13.37,11.28,8.87-6.33,8.87-6.33S123.48,315.44,109.84,316.13Z"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></path>
        <path
          d="M125.41,317.89c.06,2,1.21,2.5,1.21,2.5a4.1,4.1,0,0,1-4.45,4.73c-6.37-.65-8.38-8.32-8.38-8.32,4.63-1.84,5.67-6.95,5.87-10a16.8,16.8,0,0,0,0-2.38l8.66,5.45a30.32,30.32,0,0,0-1.93,3.79A10.84,10.84,0,0,0,125.41,317.89Z"
          fill="#ebb376"
        ></path>
        <path
          d="M127.79,309.2a31.44,31.44,0,0,0-1.54,4,10.74,10.74,0,0,0-.54,4.29c-4.87.38-6.81-10.37-6.81-10.37l0-.07a16.26,16.26,0,0,0-.24-2.37Z"
          style={{ opacity: '0.2', isolation: 'isolate' }}
        ></path>
        <path
          d="M133.33,285.18s6.36-7.32-1.25-13.45-22.64-3.37-25.41,10.48,1,19.7,1,19.7,0-8.82,2.54-11.39c0,0-.76,4.22,0,6.75s0,.93,0,.93,2.34-11.05,13.62-7.88S133.33,285.18,133.33,285.18Z"
          fill="#263238"
        ></path>
        <path
          d="M134.85,287.56c8.38,2.56,9.71,7.86,7.69,16.68-2.52,11-7.25,18-17,12.46C112.24,309.23,120.94,283.32,134.85,287.56Z"
          fill="#ebb376"
        ></path>
        <path
          d="M137.27,302.12a18.47,18.47,0,0,0,1.37,4.22,2.4,2.4,0,0,1-2.49.28Z"
          fill="#d58745"
        ></path>
        <path
          d="M132.35,299.88c-.2.69-.73,1.15-1.19,1s-.68-.8-.49-1.49.73-1.15,1.19-1S132.54,299.18,132.35,299.88Z"
          fill="#263238"
        ></path>
        <path
          d="M131.66,298.37l1.67-.17S132.23,299.26,131.66,298.37Z"
          fill="#263238"
        ></path>
        <path
          d="M141,302.14c-.19.69-.72,1.15-1.18,1s-.68-.8-.49-1.49.73-1.15,1.19-1S141.2,301.45,141,302.14Z"
          fill="#263238"
        ></path>
        <path
          d="M140.32,300.63l1.67-.17S140.88,301.52,140.32,300.63Z"
          fill="#263238"
        ></path>
        <path
          d="M130,294.59a.37.37,0,0,1-.2-.67,4.85,4.85,0,0,1,4.37-.18.36.36,0,0,1,.18.47.36.36,0,0,1-.48.19,4.22,4.22,0,0,0-3.67.12A.31.31,0,0,1,130,294.59Z"
          fill="#263238"
        ></path>
        <path
          d="M143.35,297.64a.39.39,0,0,1-.28-.13,3.69,3.69,0,0,0-2.83-1.58.37.37,0,0,1-.38-.34.36.36,0,0,1,.34-.38,4.36,4.36,0,0,1,3.43,1.83.37.37,0,0,1,0,.52A.38.38,0,0,1,143.35,297.64Z"
          fill="#263238"
        ></path>
        <path
          d="M121.39,298.89s6-5.69,7-11.18c0,0,18.67-1.72,14.35,15.38,0,0,6.19-14.47-8.11-19.19S114,300.26,119.53,308.8C119.53,308.8,117.66,302.45,121.39,298.89Z"
          fill="#263238"
        ></path>
        <path
          d="M134.22,310.39h0a4.64,4.64,0,0,1-4.63-3.93.38.38,0,0,1,.32-.41.37.37,0,0,1,.4.32,3.87,3.87,0,0,0,3.93,3.3.36.36,0,0,1,0,.72Z"
          fill="#263238"
        ></path>
        <path
          d="M119.94,297.21a.25.25,0,0,0,.13.28l.06,0,8.53,2a3.7,3.7,0,0,0,2.15,4,4.46,4.46,0,0,0,5.72-1.55,3.15,3.15,0,0,0,.26-.56,1.46,1.46,0,0,1,1.1-.32.79.79,0,0,1,.44.51,3.72,3.72,0,0,0,2.16,3.93,4.47,4.47,0,0,0,5.72-1.56,3.63,3.63,0,0,0-1.72-5.16,4.46,4.46,0,0,0-5.72,1.55,3.15,3.15,0,0,0-.26.56,1.15,1.15,0,0,0-.43-.28,1.59,1.59,0,0,0-1.11.12,3.71,3.71,0,0,0-2.16-4,4.46,4.46,0,0,0-5.72,1.55,3.73,3.73,0,0,0-.33.74l-8.49-2A.27.27,0,0,0,119.94,297.21Zm19.3,3.31a3.91,3.91,0,0,1,5-1.36,3.18,3.18,0,0,1,1.5,4.52,3.9,3.9,0,0,1-5,1.35A3.17,3.17,0,0,1,139.24,300.52Zm-9.68-2a3.91,3.91,0,0,1,5-1.36,3.18,3.18,0,0,1,1.5,4.52,3.9,3.9,0,0,1-5,1.36A3.19,3.19,0,0,1,129.56,298.51Z"
          fill="#263238"
        ></path>
        <g style={{ opacity: '0.30000000000000004', isolation: 'isolate' }}>
          <path
            d="M119.94,297.21a.25.25,0,0,0,.13.28l.06,0,8.53,2a3.7,3.7,0,0,0,2.15,4,4.46,4.46,0,0,0,5.72-1.55,3.15,3.15,0,0,0,.26-.56,1.46,1.46,0,0,1,1.1-.32.79.79,0,0,1,.44.51,3.72,3.72,0,0,0,2.16,3.93,4.47,4.47,0,0,0,5.72-1.56,3.63,3.63,0,0,0-1.72-5.16,4.46,4.46,0,0,0-5.72,1.55,3.15,3.15,0,0,0-.26.56,1.15,1.15,0,0,0-.43-.28,1.59,1.59,0,0,0-1.11.12,3.71,3.71,0,0,0-2.16-4,4.46,4.46,0,0,0-5.72,1.55,3.73,3.73,0,0,0-.33.74l-8.49-2A.27.27,0,0,0,119.94,297.21Zm19.3,3.31a3.91,3.91,0,0,1,5-1.36,3.18,3.18,0,0,1,1.5,4.52,3.9,3.9,0,0,1-5,1.35A3.17,3.17,0,0,1,139.24,300.52Zm-9.68-2a3.91,3.91,0,0,1,5-1.36,3.18,3.18,0,0,1,1.5,4.52,3.9,3.9,0,0,1-5,1.36A3.19,3.19,0,0,1,129.56,298.51Z"
            fill="#fff"
          ></path>
        </g>
        <path
          d="M122.22,301.07a3.84,3.84,0,0,0-2.93-4.56c-2.68-.51-5.55,4.44.52,7.69C120.68,304.67,121.48,303.92,122.22,301.07Z"
          fill="#ebb376"
        ></path>
        <path
          d="M106,326.82S107,348.2,108.81,353s20.69,22.48,20.69,22.48,5.27-1.17,6.14-.83,5.9,5,5.9,5H127l-1.27-2.24s-19.54-14.48-21.69-17.93c-1.59-2.55-6.7-28.52-6.7-28.52Z"
          fill="#ebb376"
        ></path>
        <path
          d="M102.13,315.88c3.39-1.76,5,3.91,7.07,15.38a120.63,120.63,0,0,1,1.69,21.95l2.9,5.15-7.32,6.74a31.42,31.42,0,0,1-7.71-11.89C96.5,346.31,90.27,322.07,102.13,315.88Z"
          fill="#263238"
        ></path>
        <path
          d="M102.13,315.88c3.39-1.76,5,3.91,7.07,15.38a120.63,120.63,0,0,1,1.69,21.95l2.9,5.15-7.32,6.74a31.42,31.42,0,0,1-7.71-11.89C96.5,346.31,90.27,322.07,102.13,315.88Z"
          style={{ fill: '#fff', opacity: '0.1', isolation: 'isolate' }}
        ></path>
        <polygon
          points="104.56 365.1 113.79 356.85 115.97 359.9 107.72 368 104.56 365.1"
          fill={color1}
        ></polygon>
        <polygon
          points="104.56 365.1 113.79 356.85 115.97 359.9 107.72 368 104.56 365.1"
          style={{ fill: '#fff', opacity: '0.4', isolation: 'isolate' }}
        ></polygon>
      </g>
    </svg>
  );
}

export default PerformaceOperatorImg;
