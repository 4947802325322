import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from '@polichat/flamboyant';

import { QuickTypes, QuickTypesText } from '../../types/constants';
import { QuickMessageSelectorOptions } from '../../types/types';

import styles from './QuickFields.module.css';
import { MenuChildrenItem } from './MenuFilters/ChildrenItem';
export interface DropMenuTypeSelectorProps {
  id: string;
  value: string;
  onChange: (value: string | null) => void;
  enableOnlyTemplate: boolean;
  enableShowOptionsListAndButtons: boolean;
  enableShowOptionsTemplate: boolean;
}
export function MenuTypeSelector(props: Readonly<DropMenuTypeSelectorProps>) {
  /**
   * Verifica o tipo de opção e informações de canal
   * para definir se a opção está visivel ou não ao usuário
   * return TRUE -> está visivel
   * return FALSE -> está desabilitada
   * @param option
   * @returns
   */
  function canShowOption(option: QuickMessageSelectorOptions) {
    switch (option) {
      case QuickTypes.QUICK:
        // é removido quando é abertura de janela de 24h
        return !props.enableOnlyTemplate;
      case QuickTypes.BUTTONS:
      case QuickTypes.LIST_OPTION:
        // é removido quando é abertura de janela de 24h
        // é removido quando canal não pode realizar envio de template
        if (
          props.enableOnlyTemplate ||
          !props.enableShowOptionsListAndButtons
        ) {
          return false;
        }
        return true;
      case QuickTypes.TEMPLATE:
        // é removido quando canal não pode realizar envio de template
        return !!props.enableShowOptionsTemplate;
      default:
        return false;
    }
  }

  function handleSelect(value: string) {
    props.onChange(value);
  }

  return (
    <div id="quick_type_dropdown">
      <DropdownToggle caret nav>
        Filtrar por tipo
      </DropdownToggle>
      <DropdownMenu className={styles.drop_container}>
        {canShowOption(QuickTypes.QUICK) && (
          <MenuChildrenItem
            active={props.value === QuickTypes.QUICK}
            handleClick={() => handleSelect(QuickTypes.QUICK)}
            text={QuickTypesText[QuickTypes.QUICK]}
          />
        )}

        {canShowOption(QuickTypes.TEMPLATE) && (
          <MenuChildrenItem
            active={props.value === QuickTypes.TEMPLATE}
            handleClick={() => handleSelect(QuickTypes.TEMPLATE)}
            text={QuickTypesText[QuickTypes.TEMPLATE]}
          />
        )}
        {canShowOption(QuickTypes.LIST_OPTION) && (
          <MenuChildrenItem
            active={props.value === QuickTypes.LIST_OPTION}
            handleClick={() => handleSelect(QuickTypes.LIST_OPTION)}
            text={QuickTypesText[QuickTypes.LIST_OPTION]}
          />
        )}
        {canShowOption(QuickTypes.BUTTONS) && (
          <MenuChildrenItem
            active={props.value === QuickTypes.BUTTONS}
            handleClick={() => handleSelect(QuickTypes.BUTTONS)}
            text={QuickTypesText[QuickTypes.BUTTONS]}
          />
        )}
      </DropdownMenu>
    </div>
  );
}
