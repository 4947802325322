import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Container, Row, Col } from '@polichat/flamboyant';
import { IconLabelContainer, HorizontalRule } from './ChannelDescriptionBanner.styles';
import { IconPlusIcon } from '../../../../assets/illustratorElement/iconPlusIcon/iconPlusIcon';

export default function ChannelBaseboardInstagramRefactor({ isConnected, namePage }) {
  const history = useHistory();

  const isConnectedBool = !!isConnected;

  const renderInstagramRow = () => {
    return (
      <Row>
        <Col lg="3" className="my-2 d-flex justify-content-center justify-content-lg-end">
          <IconLabelContainer>
            <IconPlusIcon 
              firstIcon="onlyLogo"
              secondIcon="poli-icon pi-instagram-fill"
              secondColor="#ff647c"
            />
          </IconLabelContainer>
        </Col>
        <Col lg="6" className="my-2 text-center">
          {isConnectedBool
            ? `Sua conexão já foi autorizada e configurada.`
            : `Você será direcionado ao Instagram para que a conexão seja autorizada e configurada.`}
        </Col>
        <Col lg="3" className="my-2 d-flex justify-content-center justify-content-lg-start">
          {!isConnectedBool ? (
            <Button color="primary" onClick={() => history.push('/tools/channels/add')}>
              <span>Conectar agora</span>
            </Button>
          ) : (
            <Button color="primary" disabled>
              <span>Conectar agora</span>
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div style={{ marginTop: '45px' }}>Conecte a sua conta do Instagram ao Polichat</div>
      <HorizontalRule />
      <Container fluid="true">
        {renderInstagramRow()}
      </Container>
      <HorizontalRule />
      {isConnectedBool && (
        <>
          <div style={{ marginTop: '45px' }}>Seleção de página</div>
          <HorizontalRule />
          <Container fluid="true">
            <Row>
              <Col lg="3" className="my-2 d-flex justify-content-center justify-content-lg-end">
                <IconLabelContainer>
                  <IconPlusIcon 
                    firstIcon="onlyLogo"
                    secondIcon="poli-icon pi-instagram-fill"
                    secondColor="#ff647c"
                  />
                </IconLabelContainer>
              </Col>
              <Col lg="6" className="my-2 text-center">
                Página do Instagram vinculado ao canal: "{namePage}"
              </Col>
              <Col lg="3" className="my-2 d-flex justify-content-center justify-content-lg-start"></Col>
            </Row>
          </Container>
          <HorizontalRule />
        </>
      )}
    </>
  );
}