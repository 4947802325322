import returnNameMonth from '../../../../../../../utils/returnNameMonth';
import { Credits, MonthDate } from './types';

export function getMonthAndNextMont(): MonthDate {
  let thisMonth = new Date().getMonth();
  let thisNextMonth = new Date().getMonth() + 1;
  let thisYear = new Date().getFullYear();

  // @ts-ignore
  const month = returnNameMonth(new Date(thisYear, thisMonth), 'pt-br', 'long');

  const nextMonth = returnNameMonth(
    //@ts-ignore
    new Date(thisYear, thisNextMonth),
    'pt-br',
    'short'
  );

  return { month, nextMonth };
}

const initialCredits: Credits = {
  regular: [],
  detached: {
    used: 0,
    total: 0,
    color: 'white',
    icon: { color: '', name: '' },
    name: '',
  },
  wabaGroups: [],
  postpaidCredits: false,
  newBillingModel: false,
  channels: [],
};

enum CreditsKeys {
  DETACHED = 'detachedCredits',
  WABA_GROUP = 'wabaExpenditureGroups',
}
/***
 * @param dataCredits
 * objeto com os creditos consumidos de cada canal, onde o id do canal é a key do objeto
 * @param channelsData
 * array com os canais do usuário
 * @returns objeto com o array dos canais separados por grupo waba, canais whatsapp e creditos avulsos
 */
export function separateCreditsByType(dataCredits: any, channelsData: any) {
  const credits: Credits = initialCredits;

  credits.newBillingModel = dataCredits.newBillingModel;
  credits.postpaidCredits = dataCredits.postpaidCredits;

  // creditos avulsos
  credits.detached = {
    used: dataCredits[CreditsKeys.DETACHED]?.used ?? 0,
    total: dataCredits[CreditsKeys.DETACHED]?.total ?? 0,
    color: calculatePercentChannelColor(
      dataCredits[CreditsKeys.DETACHED]?.used,
      dataCredits[CreditsKeys.DETACHED]?.total
    ),
    name: credits.newBillingModel ? 'Créditos ativos contratados' : 'Créditos adquiridos',
    icon: {
      name: 'poli-icon pi-pay-line',
      color: 'var(--primary)',
    },
  };

  // grupos de conta waba
  for (let groupKey in dataCredits[CreditsKeys.WABA_GROUP]) {
    credits.wabaGroups.push({
      id: groupKey,
      used: Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].used),
      total: Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].total),
      active: Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].active),
      receptive: Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].receptive),
      color: calculatePercentChannelColor(
        dataCredits[CreditsKeys.WABA_GROUP][groupKey].used,
        dataCredits[CreditsKeys.WABA_GROUP][groupKey].total
      ),
      name: 'Conta Waba',
      icon: {
        name: 'poli-icon pi-whatsapp-business-fill',
        color: 'var(--whatsapp-color)',
      },
      channels: [],
    });
  }

  for (let key in dataCredits) {
    // é canal
    if (!!Number(key)) {
      //usuário tem o canal na lista dele de canais
      const channelInfo = channelsData?.find(
        (channel: any) => Number(channel.id) === Number(key)
      );

      //regular channels
      if (channelInfo && !dataCredits[key]?.isWaba) {
        credits.regular.push({
          id: channelInfo.id,
          name: channelInfo.name,
          used: dataCredits[key].used,
          total: dataCredits[key].total,
          color: calculatePercentChannelColor(
            dataCredits[key].used,
            dataCredits[key].total
          ),
          icon: {
            name: 'poli-icon pi-whatsapp-fill',
            color: 'var(--whatsapp-color)',
          },
        });
      }

      // waba channels
      if (channelInfo && dataCredits[key]?.isWaba) {
        const channelData = {
          id: channelInfo.id,
          used: dataCredits[key].used,
          active: dataCredits[key].active,
          receptive: dataCredits[key].receptive,
          name: channelInfo.name,
          phone: channelInfo.phone,
          color: '',
        };

        credits.wabaGroups
          .find((wabaGroup) => Number(wabaGroup.id) === Number(dataCredits[key].groupId))
          ?.channels.push(channelData);
        credits.channels.push({
          ...channelData,
          color: calculatePercentChannelColor(dataCredits[key].used, dataCredits[CreditsKeys.DETACHED]?.total),
          icon: {
            name: 'poli-icon pi-whatsapp-api-fill',
            color: 'var(--whatsapp-color)',
          }
        });
      }
    }
  }

  return credits;
}

export function updateCredits(dataCredits: any, currentCredits: any) {
  // creditos avulsos
  currentCredits.detached = {
    ...currentCredits.detached,
    used: dataCredits[CreditsKeys.DETACHED]?.used ?? 0,
    total: dataCredits[CreditsKeys.DETACHED]?.total ?? 0,
    color: calculatePercentChannelColor(
      dataCredits[CreditsKeys.DETACHED]?.used,
      dataCredits[CreditsKeys.DETACHED]?.total
    ),
  };

  //waba groups
  for (let groupKey in dataCredits[CreditsKeys.WABA_GROUP]) {
    let groupIndex = currentCredits.wabaGroups.findIndex(
      // @ts-ignore
      (wabagroup) => wabagroup.id === groupKey
    );

    if (groupIndex > -1) {
      // set used
      currentCredits.wabaGroups[groupIndex].used = Number(
        dataCredits[CreditsKeys.WABA_GROUP][groupKey].used
      );
      // set total
      currentCredits.wabaGroups[groupIndex].total = Number(
        dataCredits[CreditsKeys.WABA_GROUP][groupKey].total
      );
      // set color
      currentCredits.wabaGroups[groupIndex].color =
        calculatePercentChannelColor(
          Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].used),
          Number(dataCredits[CreditsKeys.WABA_GROUP][groupKey].total)
        );
    }
  }

  //canais
  for (let channelkey in dataCredits) {
    if (!!Number(channelkey)) {
      //canal regular
      if (!dataCredits[channelkey].isWaba) {
        let regularIndex = currentCredits.regular.findIndex(
          // @ts-ignore
          (channel) => Number(channel.id) === Number(channelkey)
        );

        if (regularIndex > -1) {
          // set used
          currentCredits.regular[regularIndex].used = Number(
            dataCredits[channelkey].used
          );
          // set total
          currentCredits.regular[regularIndex].total = Number(
            dataCredits[channelkey].total
          );
          // set color
          currentCredits.regular[regularIndex].color =
            calculatePercentChannelColor(
              Number(dataCredits[channelkey].used),
              Number(dataCredits[channelkey].total)
            );
        }
      }

      // canal waba
      if (dataCredits[channelkey].isWaba) {
        let wabaGroupId = dataCredits[channelkey].groupId;

        let wabagroupindex = currentCredits.wabaGroups.findIndex(
          // @ts-ignore
          (wabagroup) => wabagroup.id === wabaGroupId
        );

        let wabaChannelIndex = currentCredits?.wabaGroups[
          wabagroupindex
        ]?.channels?.findIndex(
          // @ts-ignore
          (wabachannel) => wabachannel.id === Number(channelkey)
        );

        if (wabaChannelIndex > -1) {
          // set used
          currentCredits.wabaGroups[wabagroupindex].channels[
            wabaChannelIndex
          ].used = Number(dataCredits[channelkey].used);
          // set color
          currentCredits.wabaGroups[wabagroupindex].channels[
            wabaChannelIndex
          ].color = currentCredits.wabaGroups[wabagroupindex].color;
        }
      }
    }
  }
  return currentCredits;
}

export function calculatePercentChannelColor(
  used: number,
  total: number
): string {
  const percenter = Number((100 * (used / total)).toFixed(2));

  if (percenter >= 80 && percenter <= 90) return 'warning';
  if (percenter > 90) return 'danger';

  return '';
}
