import { useWhiteLabel } from "../../../hooks/useWhiteLabel";

function ExportReport() {
  let color1 = '#0000c8';
  const whitelabel = useWhiteLabel();
  if (whitelabel.isWl) {
    if (whitelabel.cssPathWl.includes('chatshub')) {
      color1 = '#FF7038';
    }
    if (whitelabel.cssPathWl.includes('auvo')) {
      color1 = '#6d26c2';
    }
    if (whitelabel.cssPathWl.includes('nectarcrm')) {
      color1 = '#ffd55b';
    }
    if (whitelabel.cssPathWl.includes('usecredituz')) {
      color1 = '#000000';
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      className="img-fluid"
      height={392}
      width={392}
    >
      <g id="freepik--background-complete--inject-899">
        <rect y="382.4" width="500" height="0.25" fill="#e6e6e6"></rect>
        <rect
          x="449.63"
          y="391.22"
          width="18.77"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="373.07"
          y="398.08"
          width="8.69"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="279.96"
          y="394.63"
          width="19.36"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="80.93"
          y="394.5"
          width="43.19"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="65.7"
          y="394.5"
          width="6.33"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <rect
          x="189.3"
          y="388.58"
          width="93.68"
          height="0.25"
          fill="#e6e6e6"
        ></rect>
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          fill="#e6e6e6"
        ></path>
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          fill="#e6e6e6"
        ></path>
        <rect
          x="58.71"
          y="73.68"
          width="202.25"
          height="144.13"
          fill="#fafafa"
        ></rect>
        <rect
          x="60.46"
          y="73.68"
          width="204.24"
          height="144.13"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="96.4"
          y="48.86"
          width="132.36"
          height="193.76"
          transform="translate(308.32 -16.84) rotate(90)"
          fill="#fafafa"
        ></rect>
        <polygon
          points="100.98 211.92 131.72 79.56 172.02 79.56 141.28 211.92 100.98 211.92"
          fill="#fff"
        ></polygon>
        <polygon
          points="147.8 211.92 178.55 79.56 196.75 79.56 166 211.92 147.8 211.92"
          fill="#fff"
        ></polygon>
        <rect
          x="192.95"
          y="145.41"
          width="132.36"
          height="0.67"
          transform="translate(404.87 -113.39) rotate(90)"
          fill="#fafafa"
        ></rect>
        <path
          d="M54.78,73.44v57.12H69.89S97,112.85,103.21,76.42a6.06,6.06,0,0,0-6-7.09H58.89A4.11,4.11,0,0,0,54.78,73.44Z"
          fill="#e0e0e0"
        ></path>
        <path
          d="M54.78,235.44V135.17H69.89s30.67,59.05,34.22,100.27Z"
          fill="#e0e0e0"
        ></path>
        <rect
          x="53.82"
          y="130.56"
          width="17.14"
          height="4.61"
          rx="1.51"
          fill="#f5f5f5"
        ></rect>
        <path
          d="M269,73.44v57.12H253.86s-27.15-17.71-33.32-54.14a6.07,6.07,0,0,1,6-7.09h38.36A4.12,4.12,0,0,1,269,73.44Z"
          fill="#e0e0e0"
        ></path>
        <path
          d="M269,235.44V135.17H253.86s-30.66,59.05-34.22,100.27Z"
          fill="#e0e0e0"
        ></path>
        <rect
          x="252.8"
          y="130.56"
          width="17.14"
          height="4.61"
          rx="1.51"
          transform="translate(522.73 265.72) rotate(180)"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="231.2"
          y="253.66"
          width="33.23"
          height="128.74"
          fill="#fafafa"
        ></rect>
        <polygon
          points="255.56 382.4 264.43 382.4 264.43 367.53 246.26 367.53 255.56 382.4"
          fill="#f4f8ff"
        ></polygon>
        <rect
          x="72.22"
          y="253.66"
          width="33.23"
          height="128.74"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="97.71"
          y="253.66"
          width="166.72"
          height="123.62"
          fill="#fafafa"
        ></rect>
        <path
          d="M147.8,231.8c0,12,11.56,21.86,25.7,21.86h12.76c14.13,0,25.69-9.84,25.69-21.86Z"
          fill="#ebebeb"
        ></path>
        <path
          d="M140.4,204.33h24s-4.17,11-4.17,17.48,18.26,31.85-7.83,31.85-7.83-25.38-7.83-31.85S140.4,204.33,140.4,204.33Z"
          fill="#e0e0e0"
        ></path>
        <rect
          x="104.59"
          y="261.19"
          width="152.96"
          height="31.17"
          fill="#ebebeb"
        ></rect>
        <polygon
          points="106.58 382.4 97.71 382.4 97.71 367.53 115.89 367.53 106.58 382.4"
          fill="#fafafa"
        ></polygon>
        <path
          d="M176.37,276.77a4.7,4.7,0,1,0,4.7-4.7A4.7,4.7,0,0,0,176.37,276.77Z"
          fill="#fafafa"
        ></path>
        <rect
          x="104.59"
          y="299.89"
          width="152.96"
          height="31.17"
          fill="#ebebeb"
        ></rect>
        <path
          d="M176.37,315.47a4.7,4.7,0,1,0,4.7-4.7A4.7,4.7,0,0,0,176.37,315.47Z"
          fill="#fafafa"
        ></path>
        <rect
          x="104.59"
          y="338.58"
          width="152.96"
          height="31.17"
          fill="#ebebeb"
        ></rect>
        <circle cx="181.07" cy="354.17" r="4.7" fill="#fafafa"></circle>
        <rect
          x="295.65"
          y="71.94"
          width="74.23"
          height="52.84"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="299.87"
          y="71.94"
          width="76.07"
          height="52.84"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="316.34"
          y="65.17"
          width="43.14"
          height="66.37"
          transform="translate(436.26 -239.55) rotate(90)"
          fill="#fff"
        ></rect>
        <rect
          x="328.9"
          y="76.85"
          width="18.01"
          height="43.01"
          transform="translate(436.26 -239.55) rotate(90)"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="384.92"
          y="98.36"
          width="45.18"
          height="47.38"
          fill="#e0e0e0"
        ></rect>
        <rect
          x="389.15"
          y="98.36"
          width="47.03"
          height="47.38"
          fill="#f5f5f5"
        ></rect>
        <rect
          x="393.82"
          y="103.38"
          width="37.68"
          height="37.33"
          transform="translate(534.71 -290.61) rotate(90)"
          fill="#fff"
        ></rect>
        <path
          d="M405.68,122.05h0a6.28,6.28,0,0,1,6.27-6.28h1.42a6.28,6.28,0,0,1,6.27,6.28h0a6.27,6.27,0,0,1-6.27,6.27H412A6.27,6.27,0,0,1,405.68,122.05Z"
          fill="#f5f5f5"
        ></path>
        <polygon
          points="500 161.85 380.7 161.85 359.01 161.85 359.01 189.42 359.01 189.42 359.01 189.42 359.01 189.42 337.32 189.42 337.32 216.99 337.32 216.99 315.63 216.99 315.63 244.56 315.63 244.56 293.94 244.56 293.94 272.13 293.94 272.13 272.25 272.13 272.25 299.69 272.25 299.69 250.56 299.69 250.56 327.26 250.56 327.26 250.56 327.26 250.56 327.26 228.87 327.26 228.87 354.83 228.87 354.83 228.87 354.83 228.87 354.83 207.18 354.83 207.18 382.4 228.87 382.4 500 382.4 500 161.85"
          fill="#fafafa"
        ></polygon>
        <rect
          x="204.95"
          y="354.83"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="226.64"
          y="327.26"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="248.33"
          y="299.69"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="270.02"
          y="272.12"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="291.71"
          y="244.56"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="313.4"
          y="216.99"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="335.09"
          y="189.42"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <rect
          x="356.78"
          y="161.85"
          width="123.63"
          height="8.27"
          fill="#ebebeb"
        ></rect>
        <polygon
          points="478.31 161.85 478.31 189.42 478.31 189.42 478.31 189.42 478.31 189.42 456.62 189.42 456.62 216.99 456.62 216.99 456.62 216.99 456.62 216.99 434.93 216.99 434.93 244.56 434.93 244.56 434.93 244.56 434.93 244.56 413.24 244.56 413.24 272.13 413.24 272.13 391.55 272.13 391.55 299.69 391.55 299.69 369.86 299.69 369.86 327.26 369.86 327.26 348.17 327.26 348.17 354.83 348.17 354.83 326.48 354.83 326.48 382.4 348.17 382.4 500 382.4 500 189.42 500 161.85 478.31 161.85"
          fill="#fff"
        ></polygon>
      </g>
      <g id="freepik--Shadow--inject-899">
        <ellipse
          cx="250"
          cy="416.24"
          rx="193.89"
          ry="11.32"
          fill="#f5f5f5"
        ></ellipse>
      </g>
      <g id="freepik--Character--inject-899">
        <path
          d="M365.15,162c1.4,4.69,8.62,13.47,9.19,19.86s-11.74,16.61-11.74,16.61-9.2-30.76-3.44-34.35S365.15,162,365.15,162Z"
          fill="#263238"
        ></path>
        <path
          d="M339.87,195.23c-2.18,1.82-4.32,3.35-6.55,4.92s-4.53,3-6.87,4.34a77.83,77.83,0,0,1-7.25,3.79c-1.26.57-2.51,1.13-3.81,1.62-.64.26-1.29.51-1.95.73l-2,.7a13.24,13.24,0,0,1-5.26.38,30.07,30.07,0,0,1-4.36-.84,57.61,57.61,0,0,1-7.81-2.78,104.07,104.07,0,0,1-14.19-7.55l1.87-3.48c2.36,1,4.78,2,7.18,2.87s4.82,1.79,7.24,2.54a60.74,60.74,0,0,0,7.2,1.88,23.5,23.5,0,0,0,3.39.41,7.07,7.07,0,0,0,2.52-.26,64.62,64.62,0,0,0,6.68-3c1.11-.58,2.21-1.17,3.29-1.81s2.18-1.26,3.25-1.92c2.15-1.33,4.25-2.74,6.33-4.19s4.15-3,6-4.47Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M345.15,184.58c-2.48-1.94-12.74,4.41-15.85,7.06l2.27,13.73s14.75-6.18,15.63-10.4C348.13,190.56,347,186.06,345.15,184.58Z"
          fill={color1}
        ></path>
        <path
          d="M345.15,184.58c-2.48-1.94-12.74,4.41-15.85,7.06l2.27,13.73s14.75-6.18,15.63-10.4C348.13,190.56,347,186.06,345.15,184.58Z"
          style={{ opacity: '0.2' }}
        ></path>
        <path
          d="M379.66,226.13l-31.14,6.07c-16.09-43.57-12.59-45-12.59-45a62.53,62.53,0,0,1,7-3.3l.42-.16c2-.73,2.4-.84,5.06-1.62,4.58-1.09,8.85-2,14.14-2.75l1-.14c1-.12,1.91-.22,2.83-.31.62-.07,1.24-.11,1.83-.15l2-.11c3.42-.17,5.87-.12,5.87-.12C375.13,205.75,376.67,213.56,379.66,226.13Z"
          fill={color1}
        ></path>
        <path
          d="M379.66,226.13l-31.14,6.07c-16.09-43.57-12.59-45-12.59-45a62.53,62.53,0,0,1,7-3.3l.42-.16c2-.73,2.4-.84,5.06-1.62,4.58-1.09,8.85-2,14.14-2.75l1-.14c1-.12,1.91-.22,2.83-.31.62-.07,1.24-.11,1.83-.15l2-.11c3.42-.17,5.87-.12,5.87-.12C375.13,205.75,376.67,213.56,379.66,226.13Z"
          style={{ opacity: '0.2' }}
        ></path>
        <path
          d="M355.27,165.13c-2,3.31,5.25,4.84,9.54,1.45,3.43-2.72,2.62-9-.32-10.46S355.27,165.13,355.27,165.13Z"
          fill={color1}
        ></path>
        <path
          d="M355.27,165.13c-2,3.31,5.25,4.84,9.54,1.45,3.43-2.72,2.62-9-.32-10.46S355.27,165.13,355.27,165.13Z"
          style={{ opacity: '0.4' }}
        ></path>
        <polygon
          points="385.58 406.47 377.79 407.05 374.94 389.12 382.73 388.54 385.58 406.47"
          fill="#e58a7b"
        ></polygon>
        <polygon
          points="440.68 381.41 434.07 385.57 422.3 371.61 428.91 367.44 440.68 381.41"
          fill="#e58a7b"
        ></polygon>
        <polygon
          points="374.94 389.12 376.52 399.08 384.2 397.79 382.74 388.55 374.94 389.12"
          fill="#cf6f64"
        ></polygon>
        <polygon
          points="428.92 367.45 422.3 371.61 427.18 377.39 433.94 373.4 428.92 367.45"
          fill="#cf6f64"
        ></polygon>
        <path
          d="M349,232.2s-.11,61.82,4.94,85.1c5.26,24.22,20.73,79.32,20.73,79.32l11.22-1.45s-8.77-55.66-12.51-79.73c-4.07-26.23-1.57-87.68-1.57-87.68Z"
          fill={color1}
        ></path>
        <path
          d="M349,232.2s-.11,61.82,4.94,85.1c5.26,24.22,20.73,79.32,20.73,79.32l11.22-1.45s-8.77-55.66-12.51-79.73c-4.07-26.23-1.57-87.68-1.57-87.68Z"
          style={{ opacity: '0.7000000000000001' }}
        ></path>
        <path
          d="M371.11,255.16l-5.5-5.48c.21,23.75,1.54,35.48,6.11,48.26C371,284.43,370.94,268.45,371.11,255.16Z"
          style={{ opacity: '0.5' }}
        ></path>
        <path
          d="M356.74,230.6s16.77,51.09,25.09,80.52c8.78,31.05,42.44,65.39,42.44,65.39l9.66-5.58s-23-32.6-31.46-61.57c-17.2-58.81-10.13-71.27-22.82-83.23Z"
          fill={color1}
        ></path>
        <path
          d="M356.74,230.6s16.77,51.09,25.09,80.52c8.78,31.05,42.44,65.39,42.44,65.39l9.66-5.58s-23-32.6-31.46-61.57c-17.2-58.81-10.13-71.27-22.82-83.23Z"
          style={{ opacity: '0.7000000000000001' }}
        ></path>
        <path
          d="M343.56,147.09c4.46,1.49,8.51,12.56,8.68,16.92s-12.92,10.37-12.92,10.37-5.77-14-4.16-23C335.86,147.47,343.56,147.09,343.56,147.09Z"
          fill="#263238"
        ></path>
        <path
          d="M282.35,197.43l-5.29-5.76-1.73,7.22s3.76,3.32,6.8,2.18Z"
          fill="#e58a7b"
        ></path>
        <polygon
          points="271.7 190.02 268 192.96 275.33 198.89 277.06 191.67 271.7 190.02"
          fill="#e58a7b"
        ></polygon>
        <path
          d="M432,384.08l6.18-6.23a.63.63,0,0,1,.82-.06l5.63,4.18a1.25,1.25,0,0,1,.11,1.87c-2.2,2.13-3.36,3.05-6.08,5.79-1.67,1.69-3.94,4.32-6.25,6.65s-4.74.16-4-1c3.37-5.27,2.77-7.24,3-9.92A2,2,0,0,1,432,384.08Z"
          fill="#263238"
        ></path>
        <path
          d="M377.27,405.93h8.78a.66.66,0,0,1,.65.54l1.38,6.94a1.18,1.18,0,0,1-1.17,1.39c-3.07-.05-4.55-.23-8.41-.23-2.37,0-7.27.24-10.55.24s-3.63-3.24-2.28-3.53c6-1.32,8.35-3.13,10.32-4.87A2,2,0,0,1,377.27,405.93Z"
          fill="#263238"
        ></path>
        <path
          d="M377.09,194.31l-4.8,2.86L377.83,218C376.62,211.69,377.16,204.76,377.09,194.31Z"
          style={{ fill: color1, opacity: '0.27', mixBlendMode: 'multiply' }}
        ></path>
        <path
          d="M349.81,161.79c3.41,2.85,8.36,7,14.19,2.15,5-4.18,8.5-15.15-.47-15.55C358.84,148.18,349.81,161.79,349.81,161.79Z"
          fill="#263238"
        ></path>
        <path
          d="M358.05,163.74c0,4.72.73,13.23,4.5,15.65,0,0-.34,4.86-8.8,6.5-9.29,1.81-5.34-3.75-5.34-3.75,4.84-2.2,4-5.94,2.4-9.3Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M355.1,167.45l-4.28,5.39a15.19,15.19,0,0,1,.92,2.42c1.88-.66,4.13-3.3,3.94-5.37A6.83,6.83,0,0,0,355.1,167.45Z"
          fill="#cf6f64"
        ></path>
        <path
          d="M358.5,152.9c1,7.69,1.61,10.92-1.47,15.57-4.63,7-14.29,6.55-17.58-.68-3-6.51-4.12-18,2.72-22.45A10.57,10.57,0,0,1,358.5,152.9Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M345.14,139.43c-5.2,3.69,5.23,4.78,5.24,10.13,0,2.21,3.63,9.22,8.79,8.82l5-8.88C369.43,141,357,131,345.14,139.43Z"
          fill="#263238"
        ></path>
        <path
          d="M345.14,139.43c-9.15-.48-13.14,6.8-12.09,13.34a59.6,59.6,0,0,1,19.54-1.55C365.13,152.5,345.14,139.43,345.14,139.43Z"
          fill="#263238"
        ></path>
        <path
          d="M361,159.53a5.65,5.65,0,0,1-1.31,4.12c-1.29,1.51-3,.62-3.6-1.17-.51-1.62-.45-4.35,1.29-5.21A2.55,2.55,0,0,1,361,159.53Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M347.39,157.27c.12.62-.11,1.18-.51,1.26s-.83-.36-.95-1,.11-1.19.51-1.27S347.27,156.65,347.39,157.27Z"
          fill="#263238"
        ></path>
        <path
          d="M340.4,158.63c.12.62-.11,1.19-.51,1.27s-.83-.36-.95-1,.11-1.19.51-1.26S340.28,158,340.4,158.63Z"
          fill="#263238"
        ></path>
        <path
          d="M339.64,157.65l-1.57-.13S339.06,158.53,339.64,157.65Z"
          fill="#263238"
        ></path>
        <path
          d="M342.46,159.34a17.1,17.1,0,0,1-1.44,4.37,2.71,2.71,0,0,0,2.29,0Z"
          fill="#df5753"
        ></path>
        <path
          d="M345.92,165.71a.18.18,0,0,1-.18-.15.18.18,0,0,1,.15-.21,5,5,0,0,0,3.62-2.7.18.18,0,1,1,.34.14,5.27,5.27,0,0,1-3.89,2.92Z"
          fill="#263238"
        ></path>
        <path
          d="M349,154.61a.38.38,0,0,1-.25-.09,2.94,2.94,0,0,0-2.56-.76A.37.37,0,0,1,346,153a3.71,3.71,0,0,1,3.25.93.37.37,0,0,1,0,.52A.36.36,0,0,1,349,154.61Z"
          fill="#263238"
        ></path>
        <path
          d="M336.53,156a.32.32,0,0,1-.14,0,.37.37,0,0,1-.2-.49,3.67,3.67,0,0,1,2.53-2.23.36.36,0,0,1,.43.3.36.36,0,0,1-.29.43,3,3,0,0,0-2,1.79A.38.38,0,0,1,336.53,156Z"
          fill="#263238"
        ></path>
        <path
          d="M429.68,387.71a1.06,1.06,0,0,1-.86-.48.63.63,0,0,1-.11-.62c.35-.94,3.11-1.54,3.42-1.61a.19.19,0,0,1,.19.08.17.17,0,0,1,0,.2c-.54.89-1.6,2.38-2.59,2.43Zm2.09-2.25c-1.09.28-2.53.78-2.71,1.28a.25.25,0,0,0,.06.27.68.68,0,0,0,.6.33C430.25,387.31,431,386.62,431.77,385.46Z"
          fill={color1}
        ></path>
        <path
          d="M431.32,385.46c-1,0-2.25-.25-2.62-.79a.65.65,0,0,1,.07-.81,1,1,0,0,1,.73-.38h.08c1.12,0,2.66,1.51,2.72,1.57a.18.18,0,0,1,.05.18.19.19,0,0,1-.13.13A3.61,3.61,0,0,1,431.32,385.46Zm-1.74-1.61h-.06a.6.6,0,0,0-.46.24c-.14.19-.1.3-.05.37.3.46,1.83.72,2.77.61A4.42,4.42,0,0,0,429.58,383.85Z"
          fill={color1}
        ></path>
        <path
          d="M374.4,407.3a3,3,0,0,1-1.91-.48,1,1,0,0,1-.38-.89.58.58,0,0,1,.28-.5c.92-.54,4.14,1.06,4.5,1.24a.19.19,0,0,1,.1.19.19.19,0,0,1-.14.16A13.33,13.33,0,0,1,374.4,407.3Zm-1.48-1.62a.66.66,0,0,0-.34.07.19.19,0,0,0-.1.19.7.7,0,0,0,.25.6c.5.43,1.79.5,3.49.22A10.46,10.46,0,0,0,372.92,405.68Z"
          fill={color1}
        ></path>
        <path
          d="M376.81,407h-.07c-1-.43-2.93-2.13-2.79-3,0-.2.18-.46.68-.51h.15c1.74,0,2.19,3.18,2.21,3.32a.2.2,0,0,1-.07.18A.2.2,0,0,1,376.81,407Zm-2-3.16h-.11c-.32,0-.34.15-.34.19-.08.53,1.24,1.86,2.24,2.45C376.4,405.7,375.89,403.86,374.77,403.86Z"
          fill={color1}
        ></path>
        <path
          d="M346.63,156.29l-1.57-.14S346.05,157.17,346.63,156.29Z"
          fill="#263238"
        ></path>
        <path
          d="M353.5,192.54c-16.73,3.73-11.56-6.39-10.56-8.62l.42-.16c2-.73,2.4-.84,5.06-1.62a100.71,100.71,0,0,1,14.14-2.75l1-.14c1-.13,1.91-.23,2.83-.31.62-.07,1.24-.11,1.83-.15l2-.12h0C370.56,180.23,371.39,188.57,353.5,192.54Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M379.76,224.21l1.95,2.52c.15.19-.06.46-.42.53l-32.66,6.36c-.28.06-.54,0-.59-.21l-.83-2.73c0-.19.16-.4.46-.46l31.54-6.14A.57.57,0,0,1,379.76,224.21Z"
          fill="#26395a"
        ></path>
        <path
          d="M376.21,228.56l.85-.16c.17,0,.28-.14.25-.25l-1.1-3.54c0-.11-.2-.16-.37-.13l-.84.16c-.17,0-.28.15-.25.25l1.1,3.55C375.88,228.54,376.05,228.6,376.21,228.56Z"
          fill={color1}
        ></path>
        <path
          d="M357.08,240.36l-6.51-2.42,1,6.19s4.72,1.7,7.13-.48Z"
          fill="#e58a7b"
        ></path>
        <polygon
          points="344.37 239.58 342.82 243.64 351.57 244.13 350.57 237.94 344.37 239.58"
          fill="#e58a7b"
        ></polygon>
        <path
          d="M351.06,233.46l.85-.16c.16,0,.28-.14.24-.25l-1.1-3.54c0-.11-.19-.16-.36-.13l-.85.16c-.17,0-.28.15-.24.25l1.1,3.55C350.73,233.44,350.89,233.5,351.06,233.46Z"
          fill={color1}
        ></path>
        <path
          d="M363.64,231l.84-.16c.17,0,.28-.14.25-.25l-1.1-3.54c0-.11-.2-.16-.37-.13l-.84.16c-.17,0-.28.15-.25.25l1.1,3.55A.32.32,0,0,0,363.64,231Z"
          fill={color1}
        ></path>
        <path
          d="M360.08,175.42c.71-.27,1.6,2.23,1.38,2.55-.51.73-9.85,3.28-10.27,1.85a6.24,6.24,0,0,1,.35-2.28C351.81,177.06,353.88,177.94,360.08,175.42Z"
          fill={color1}
        ></path>
        <path
          d="M358.5,175.48c.36-.83,1.39-2.16,2.29-1.4s1.28,2.4.69,2.67S357.88,176.87,358.5,175.48Z"
          fill={color1}
        ></path>
        <path
          d="M360.41,175.91c.62-.66,2-1.57,2.63-.55s.4,2.69-.24,2.74S359.36,177,360.41,175.91Z"
          fill={color1}
        ></path>
        <path
          d="M360.75,175.76a2.77,2.77,0,0,1,1.39-2.67c1.14-.74,2.25-1.22,2.69-2a5.52,5.52,0,0,1,.11,2.24c-.23,1.07-3.78,1.89-3.54,2.82Z"
          fill={color1}
        ></path>
        <path
          d="M360.6,175.71a2.77,2.77,0,0,1-.28-3c.54-1.24,1.22-2.24,1.18-3.12a5.57,5.57,0,0,1,1.31,1.82c.38,1-2.15,3.63-1.44,4.29Z"
          fill={color1}
        ></path>
        <path
          d="M430.15,371.06a.12.12,0,0,1-.1-.06c-.23-.32-23.09-32.94-31.47-61.6A420,420,0,0,1,386,253.32c-1.73-10.68-2.78-17.13-6.22-22.42a.13.13,0,0,1,0-.18.13.13,0,0,1,.17,0c3.48,5.34,4.53,11.81,6.26,22.52a421.15,421.15,0,0,0,12.6,56c8.37,28.62,31.21,61.2,31.44,61.53a.14.14,0,0,1,0,.17A.13.13,0,0,1,430.15,371.06Z"
          fill={color1}
        ></path>
        <path
          d="M430.15,371.06a.12.12,0,0,1-.1-.06c-.23-.32-23.09-32.94-31.47-61.6A420,420,0,0,1,386,253.32c-1.73-10.68-2.78-17.13-6.22-22.42a.13.13,0,0,1,0-.18.13.13,0,0,1,.17,0c3.48,5.34,4.53,11.81,6.26,22.52a421.15,421.15,0,0,0,12.6,56c8.37,28.62,31.21,61.2,31.44,61.53a.14.14,0,0,1,0,.17A.13.13,0,0,1,430.15,371.06Z"
          style={{ opacity: '0.4' }}
        ></path>
        <polygon
          points="387.9 395.13 372.62 397.11 371.62 392.85 387.53 389.48 387.9 395.13"
          fill={color1}
        ></polygon>
        <polygon
          points="434.74 370.59 423.47 377.1 419.67 373.85 432 365.51 434.74 370.59"
          fill={color1}
        ></polygon>
        <path
          d="M381.85,189.13c.54,2.78.85,5.4,1.15,8.11s.41,5.39.49,8.1a80.09,80.09,0,0,1-.18,8.19c-.11,1.37-.23,2.74-.42,4.12-.08.68-.17,1.37-.3,2.06l-.37,2.12a13.32,13.32,0,0,1-2.21,4.79,29.67,29.67,0,0,1-2.84,3.41,56.66,56.66,0,0,1-6.2,5.5,103.64,103.64,0,0,1-13.46,8.79L355.37,241c2-1.59,4-3.23,6-4.9s3.9-3.36,5.73-5.12a62.64,62.64,0,0,0,5.11-5.39,23.76,23.76,0,0,0,2-2.78,7,7,0,0,0,1-2.33,66.19,66.19,0,0,0,.57-7.32c0-1.25,0-2.5,0-3.75s-.05-2.52-.11-3.78c-.12-2.52-.35-5-.62-7.56s-.62-5.09-1-7.46Z"
          fill="#e58a7b"
        ></path>
        <path
          d="M380.64,180.63c4.27,3.46,3.25,13.74,3.25,13.74l-14.4,4.74s.66-11.85,1.38-16.11C371.71,177.93,376.43,177.22,380.64,180.63Z"
          fill={color1}
        ></path>
        <path
          d="M380.64,180.63c4.27,3.46,3.25,13.74,3.25,13.74l-14.4,4.74s.66-11.85,1.38-16.11C371.71,177.93,376.43,177.22,380.64,180.63Z"
          style={{ opacity: '0.2' }}
        ></path>
      </g>
      <g id="freepik--Documents--inject-899">
        <path
          d="M190.41,223.53h0A11,11,0,0,0,179.69,215h-80a11,11,0,0,0-11,11V403.82a11,11,0,0,0,11,11h254a11,11,0,0,0,11-11V254.45a11,11,0,0,0-11-11H215.44A25.68,25.68,0,0,1,190.41,223.53Z"
          fill={color1}
        ></path>
        <path
          d="M190.41,223.53h0A11,11,0,0,0,179.69,215h-80a11,11,0,0,0-11,11V403.82a11,11,0,0,0,11,11h254a11,11,0,0,0,11-11V254.45a11,11,0,0,0-11-11H215.44A25.68,25.68,0,0,1,190.41,223.53Z"
          style={{ opacity: '0.6000000000000001' }}
        ></path>
        <path
          d="M162.17,168.15l7.13,23.09a11.61,11.61,0,0,0,14.51,7.66l23.08-7.13Z"
          style={{ fill: color1, opacity: '0.22' }}
        ></path>
        <path
          d="M162.17,168.15l-82.5,25.49A11.59,11.59,0,0,0,72,208.14l47.1,152.48a11.6,11.6,0,0,0,14.51,7.66L239.2,335.66a11.59,11.59,0,0,0,7.66-14.5l-40-129.39Z"
          fill={color1}
        ></path>
        <path
          d="M162.17,168.15l-82.5,25.49A11.59,11.59,0,0,0,72,208.14l47.1,152.48a11.6,11.6,0,0,0,14.51,7.66L239.2,335.66a11.59,11.59,0,0,0,7.66-14.5l-40-129.39Z"
          style={{ fill: '#fff', opacity: '0.8' }}
        ></path>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M206.56,329l-68.64,21.2a5.08,5.08,0,0,1-6.34-3.34h0a5.08,5.08,0,0,1,3.35-6.33l68.64-21.21a5.08,5.08,0,0,1,6.34,3.35h0A5.08,5.08,0,0,1,206.56,329Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M132.69,204.58l-36.3,11.21a5.09,5.09,0,0,1-6.34-3.34h0a5.09,5.09,0,0,1,3.35-6.34l36.3-11.21a5.07,5.07,0,0,1,6.34,3.34h0A5.09,5.09,0,0,1,132.69,204.58Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M162.31,216.47l-60,18.53A5.08,5.08,0,0,1,96,231.65h0a5.07,5.07,0,0,1,3.34-6.33l60-18.53a5.08,5.08,0,0,1,6.34,3.34h0A5.1,5.1,0,0,1,162.31,216.47Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M195.81,227.16l-87.56,27a5.07,5.07,0,0,1-6.33-3.34h0a5.09,5.09,0,0,1,3.34-6.34l87.56-27a5.08,5.08,0,0,1,6.33,3.34h0A5.08,5.08,0,0,1,195.81,227.16Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M201.74,246.36l-50.9,15.73a5.08,5.08,0,0,1-6.33-3.35h0a5.08,5.08,0,0,1,3.34-6.33l50.9-15.73a5.1,5.1,0,0,1,6.34,3.35h0A5.08,5.08,0,0,1,201.74,246.36Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M127.5,269.3l-13.31,4.11a5.08,5.08,0,0,1-6.34-3.34h0a5.08,5.08,0,0,1,3.35-6.34l13.31-4.11a5.09,5.09,0,0,1,6.34,3.34h0A5.1,5.1,0,0,1,127.5,269.3Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M207.68,265.57l-87.56,27.05a5.08,5.08,0,0,1-6.33-3.35h0a5.07,5.07,0,0,1,3.34-6.33l87.56-27.05a5.08,5.08,0,0,1,6.33,3.34h0A5.08,5.08,0,0,1,207.68,265.57Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M179.82,295.21l-53.77,16.61a5.08,5.08,0,0,1-6.33-3.34h0a5.09,5.09,0,0,1,3.34-6.34l53.77-16.61a5.09,5.09,0,0,1,6.34,3.35h0A5.07,5.07,0,0,1,179.82,295.21Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M213.61,284.78l-14.35,4.43a5.09,5.09,0,0,1-6.34-3.35h0a5.08,5.08,0,0,1,3.35-6.33l14.35-4.43a5.07,5.07,0,0,1,6.33,3.34h0A5.08,5.08,0,0,1,213.61,284.78Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.28' }}>
          <path
            d="M219.54,304,132,331a5.08,5.08,0,0,1-6.34-3.35h0a5.07,5.07,0,0,1,3.35-6.33l87.55-27.05a5.1,5.1,0,0,1,6.34,3.35h0A5.08,5.08,0,0,1,219.54,304Z"
            fill={color1}
          ></path>
        </g>
        <path
          d="M221.42,110.81l26.18,58.34a6.31,6.31,0,0,1-3.16,8.32L133.38,227.32a6.3,6.3,0,0,1-8.32-3.16L98.87,165.82a6.3,6.3,0,0,1,3.16-8.33l111.06-49.85a6.32,6.32,0,0,1,8.33,3.17Z"
          fill={color1}
        ></path>
        <path
          d="M221.42,110.81l26.18,58.34a6.31,6.31,0,0,1-3.16,8.32L133.38,227.32a6.3,6.3,0,0,1-8.32-3.16L98.87,165.82a6.3,6.3,0,0,1,3.16-8.33l111.06-49.85a6.32,6.32,0,0,1,8.33,3.17Z"
          style={{ opacity: '0.30000000000000004' }}
        ></path>
        <rect
          x="200.4"
          y="108.68"
          width="3.89"
          height="1"
          transform="translate(-26.96 92.38) rotate(-24.16)"
          fill={color1}
        ></rect>
        <rect
          x="184"
          y="114.19"
          width="12.13"
          height="1"
          transform="translate(-30.29 87.83) rotate(-24.16)"
          fill={color1}
        ></rect>
        <rect
          x="99.33"
          y="171.59"
          width="1"
          height="7.4"
          transform="translate(-63.04 56.26) rotate(-24.18)"
          fill={color1}
        ></rect>
        <path
          d="M217.7,107.43l-28.64,56.1a25.65,25.65,0,0,1-29.3,13.15L98.81,160.79a6.39,6.39,0,0,1,3.22-3.3l111.06-49.85A6.3,6.3,0,0,1,217.7,107.43Z"
          style={{ fill: color1, opacity: '0.6000000000000001' }}
        ></path>
        <path
          d="M257.07,179.23l-86.18,5.32A11.6,11.6,0,0,0,160,196.84l9.83,159.28A11.6,11.6,0,0,0,182.14,367l110.3-6.81a11.58,11.58,0,0,0,10.86-12.29L295,212.73Z"
          fill={color1}
        ></path>
        <path
          d="M257.07,179.23l-86.18,5.32A11.6,11.6,0,0,0,160,196.84l9.83,159.28A11.6,11.6,0,0,0,182.14,367l110.3-6.81a11.58,11.58,0,0,0,10.86-12.29L295,212.73Z"
          style={{ fill: '#fff', opacity: '0.8' }}
        ></path>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M262.28,346l-71.71,4.43a5.08,5.08,0,0,1-5.36-4.75h0a5.09,5.09,0,0,1,4.74-5.37l71.71-4.42a5.08,5.08,0,0,1,5.37,4.74h0A5.08,5.08,0,0,1,262.28,346Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M219.84,207.68,181.91,210a5.09,5.09,0,0,1-5.37-4.74h0a5.09,5.09,0,0,1,4.75-5.37l37.93-2.34a5.07,5.07,0,0,1,5.36,4.74h0A5.08,5.08,0,0,1,219.84,207.68Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M245.82,226.22l-62.67,3.86a5.07,5.07,0,0,1-5.37-4.74h0a5.08,5.08,0,0,1,4.75-5.37l62.66-3.86a5.07,5.07,0,0,1,5.37,4.74h0A5.08,5.08,0,0,1,245.82,226.22Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M275.85,244.51l-91.46,5.64A5.09,5.09,0,0,1,179,245.4h0a5.07,5.07,0,0,1,4.74-5.36l91.47-5.65a5.08,5.08,0,0,1,5.36,4.75h0A5.08,5.08,0,0,1,275.85,244.51Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M277.09,264.57l-53.17,3.28a5.08,5.08,0,0,1-5.37-4.74h0a5.07,5.07,0,0,1,4.74-5.37l53.17-3.28a5.07,5.07,0,0,1,5.37,4.74h0A5.08,5.08,0,0,1,277.09,264.57Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M199.53,269.35l-13.91.86a5.07,5.07,0,0,1-5.36-4.74h0A5.08,5.08,0,0,1,185,260.1l13.91-.86a5.09,5.09,0,0,1,5.37,4.75h0A5.08,5.08,0,0,1,199.53,269.35Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M278.33,284.63l-91.47,5.64a5.08,5.08,0,0,1-5.37-4.74h0a5.09,5.09,0,0,1,4.75-5.37l91.46-5.64a5.08,5.08,0,0,1,5.37,4.75h0A5.07,5.07,0,0,1,278.33,284.63Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M244.27,306.87l-56.17,3.47a5.09,5.09,0,0,1-5.37-4.75h0a5.08,5.08,0,0,1,4.75-5.36l56.17-3.47a5.08,5.08,0,0,1,5.36,4.75h0A5.06,5.06,0,0,1,244.27,306.87Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M279.56,304.7l-15,.92a5.07,5.07,0,0,1-5.36-4.74h0a5.07,5.07,0,0,1,4.74-5.37l15-.92a5.07,5.07,0,0,1,5.37,4.74h0A5.09,5.09,0,0,1,279.56,304.7Z"
            fill={color1}
          ></path>
        </g>
        <g style={{ opacity: '0.22' }}>
          <path
            d="M280.8,324.76l-91.46,5.64a5.08,5.08,0,0,1-5.37-4.74h0a5.08,5.08,0,0,1,4.74-5.37l91.47-5.64a5.07,5.07,0,0,1,5.36,4.74h0A5.07,5.07,0,0,1,280.8,324.76Z"
            fill={color1}
          ></path>
        </g>
        <path
          d="M257.07,179.23l1.49,24.12a11.6,11.6,0,0,0,12.29,10.87L295,212.73Z"
          style={{ fill: color1, opacity: '0.22' }}
        ></path>
        <path
          d="M228.85,239.4h0a8.65,8.65,0,0,0-9-8.53H139.75c-6.07,0-12,4.93-13.14,11L95,403.82c-1.18,6.07,2.78,11,8.85,11h254c6.07,0,11.95-4.92,13.14-11l26.07-133.5c1.18-6.08-2.78-11-8.85-11H250C238,259.32,229.26,251.06,228.85,239.4Z"
          style={{ fill: color1, opacity: '0.5' }}
        ></path>
      </g>
    </svg>
  );
}

export default ExportReport;