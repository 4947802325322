import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import './styles.css';

import ReactSelect from 'react-select';

import checkAvatar from '../../../utils/checkAvatar';
import { getCompanyOfContact } from '../../../utils/crm/index';
import setIconChannel from '../../../utils/setIconChannel';
//import ChannelIcon from '../../chat/panel/ChannelIcon';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../store/modules/chat/actions';
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Icon,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from '@polichat/flamboyant';

import { cpfMask } from '../../../utils/cpfMask';
import { isMobile } from 'react-device-detect';
import { Close } from '@poli-ink/react';

// Libs que criam o componente responsável
// por gerenciar máscaras de telefone + código de país
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Loading } from 'src/components/chat/loading';

import { ContactsService } from '../../../services/tools/chat/addContact';
import listCompanies from '../../tools/integrations/crmRequests/listCompanies';
import ModalMessage from '../../common/modals/ModalMessage';
import { useRestoreContact } from './useRestoreContact';
import listCompanyByContact from '../../tools/integrations/crmRequests/listCompanyByContact';
import { useWhiteLabel } from '../../../hooks/useWhiteLabel';
import { PhoneNumberUtil } from 'google-libphonenumber';

//const ddiToNotFormat = ['52', '54', '353'];

function ContactForm({
  chat,
  props,
  setOpenAddContact,
  setOpenEditContact,
  openChatTab,
  users,
  customerId,
  createContactRequest,
  updateContactRequest,
  contactToUpdate,
  contactToAdd,
  currentChatSelected,
  // Vcard
  unsetAddCardContact,
  deleteContactExternalRequest,
  //mobile devices
  showBlockTwo,
  userConfig,
  isSavingContact = false,
}) {
  // Estados para armazenar variáveis locais
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [ddi, setDDI] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [address, setAddress] = useState('');
  const [organization, setOrganization] = useState('');
  const [company_id, setCompanyId] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [disabledCompanySelector, setDisabledCompanySelector] = useState(false);
  const [companySelectorPlaceholder, setCompanySelectorPlaceholder] = useState(
    'Selecione a Empresa'
  );
  const [companiesList, setCompaniesList] = useState([]);
  const [operator, setOperator] = useState('');
  const [channelPhone, setChannelPhone] = useState('');
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companiesListLoading, setCompaniesListLoading] = useState(true);
  const [isLoadingDelay, setIsLoadingDelay] = useState(false);
  const [errorFoundCompany, setErrorFoundCompany] = useState(false);

  // Estados para desabilitar formulários
  const [disableInput, setDisableInput] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [deleteExternalModal, setDeleteExternalModal] = useState(false);
  const [externalSelectedToDelete, setExternalSelectedToDelete] = useState(-1);
  let operatorInteger;
  const charLimit = 191;
  const observationLength = organization ? organization.length : 0;
  const handleObservationChange = (e) => {
    const value = e.target.value.slice(0, charLimit);
    setOrganization(value);
  };
  const customerHasCrm =
    chat.config?.general?.crm.permissions?.customerPermission;
  useEffect(() => {
    if (!customerHasCrm) {
      setCompaniesListLoading(false);
      setDisabledCompanySelector(true);
      setCompanySelectorPlaceholder('Ative o CRM para vincular uma empresa');
    }
  }, [customerHasCrm]);
  let contactObject; // Variável utilizada para armazenar o objeto Contato
  const currentUser = chat.config?.user;
  let superRole = currentUser.superRole;
  const isWl = useWhiteLabel().isWl;

  const [contactActionDebug, setContactActionDebug] = useState(false);
  const [contactActionDelay, setContactActionDelay] = useState(0);

  const [deletedContactId, setDeletedContactId] = useState(null);
  const {
    restoreContact,
    showRestoreContactModal,
    handleShowRestoreContactModal,
  } = useRestoreContact();

  const selectStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base, { isDisabled }) => ({
      ...base,
      boxShadow: 'none',
      borderColor: 'var(--border-color-dark)',
      '&:hover': {
        borderColor: 'var(--link-button-dark)',
      },
      '&:focus': {
        'border-color': 'var(--primary)',
        'box-shadow': '0 0 0 0.2rem var(--primary-25)',
      },
      backgroundColor: isDisabled ? undefined : 'var(--backgroundInput)',
      opacity: isDisabled ? 0.65 : 1,
    }),
    singleValue: (base) => ({
      ...base,
      color: 'var(--textDefault)',
      padding: '0 0.5em',
    }),
    input: (base) => ({
      ...base,
      color: 'var(--textDefault)',
    }),
    valueContainer: (base) => ({
      ...base /* , height: '44px' */,
      padding: '0',
    }),
    indicatorsContainer: (base) => ({
      ...base /* , height: '44px' */,
      padding: '0',
    }),
    option: (base, { isSelected, isFocused }) => ({
      ...base,
      color: isSelected || isFocused ? 'white' : 'var(--tertiary-font-color)',
      backgroundColor: isSelected || isFocused ? '#1A49D2' : undefined,
      height: '1.5em',
      padding: '0 0.5em',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'var(--primary-background-color)',
      padding: '0',
      margin: 0,
      border: '1px solid var(--border-color-dark)',
      borderRadius: 0,
    }),
  };

  const fetchCompaniesList = async () => {
    try {
      setCompaniesListLoading(true);
      const response = await listCompanies(customerId);
      const crmCompanies = response?.data || [];

      if (crmCompanies?.length) {
        const mappedCompanies = crmCompanies?.map((company) => {
          return {
            value: company.id,
            label: company.name,
          };
        });

        setCompaniesList(mappedCompanies);

        if (mappedCompanies?.length) {
          // Verificando se o contato já possui uma company vinculada.
          if (!selectedCompany) {
            const contactCompany = await listCompanyByContact(
              contactToUpdate.id
            );

            if (contactCompany?.data[0].id === null && contactCompany?.data[0].name === null) {
              setCompanySelectorPlaceholder('Selecione a Empresa');
              setCompaniesListLoading(false);
              setErrorFoundCompany(false);
              return;
            };

            const companySelected = {
              value: contactCompany?.data[0].id,
              label: contactCompany?.data[0].name,
            };

            if (companySelected) {
              setSelectedCompany(companySelected);
              setCompaniesListLoading(false);
              setErrorFoundCompany(false);
            } 
          }
        }
      } else {
        setCompanySelectorPlaceholder('Você não possui empresas cadastradas');
        setErrorFoundCompany(false);
        setCompaniesListLoading(false);
        setDisabledCompanySelector(true);
      }
    } catch (error) {
      if (props.type === 'add') {
        setCompanySelectorPlaceholder('Selecione a Empresa');
        setCompaniesListLoading(false);
        setErrorFoundCompany(false);
        return;
      } 
      setErrorFoundCompany(true);
      setCompaniesListLoading(false);
    }
  };

  useEffect(() => {
    if (isLoadingDelay) {
      if (contactActionDebug) {
        console.log(
          `waiting delay of ${contactActionDelay}ms to enable button action`
        );
      }
      setIsLoadingDelay(false);

      setTimeout(() => {
        setIsLoading(false);
      }, contactActionDelay);
    }
  }, [isLoadingDelay]);

  function handleDeleteExternal(external) {
    let data = {
      customer_id: external.customer_id,
      contact_id: external.contact_id || currentChatSelected,
      contact_external_id: external.id,
      user_id: currentUser.id,
    };

    if (data) {
      deleteContactExternalRequest(data);
    }
  }

  function extractDialCode(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const fullPhone = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    const parsedNumber = phoneUtil.parse(fullPhone);
    const extractedDDI = parsedNumber.getCountryCode();

    return extractedDDI;
  }

  async function verifyContactAtBase(phone) {
    try {
      const response = await ContactsService.getContactInfo({
        customer_id: customerId,
        contact_number: phone,
      });

      if (response?.length) {
        const contactToRestore = response.reduce((a, b) =>
          a.contact_id > b.contact_id ? a : b
        );

        if (contactToRestore.deleted_at !== null) {
          setDeletedContactId(contactToRestore.contact_id);
          handleShowRestoreContactModal();
          return true;
        }
      }

      if (response.length > 0) {
        const { user_name } = response;

        toast.info(
          <div style={{ margin: 0 }}>
            <span>Contato já existente nessa empresa</span>
            {user_name && (
              <>
                <br />
                <span>Contato está com o Atendente: {user_name}</span>
              </>
            )}
          </div>
        );
        return true;
      }

      return false;
    } catch (error) {
      toast.error('Error ao salvar contato, tente novamente!');
    }
  }

  const submitSaveContact = async () => {
    //verificar se contato existe
    let hasContact = await verifyContactAtBase(phone);
    if (hasContact) {
      setIsLoadingDelay(true);
      //setIsLoading(false);
      return;
    }

    //the initial code to add contact
    // Alterando o objeto para enviar o telefone sem o ddi
    contactObject = {
      ...contactObject,
      phone: phone.replace(ddi, ''),
      company_id,
    };
    createContactRequest(contactObject, true);
    isMobile && showBlockTwo();
    setIsLoadingDelay(true);
    //setIsLoading(false);
  };

  const submitEditContact = () => {
    //the initial code to edit contact
    operatorInteger = parseInt(operator);
    // contactObject.channels[0].uid = contactObject.channels[0].uid+"@c.us" ;
    // Se o Canal for alterado então adiciona o objeto canais ao objeto contato que será enviado
    if (!disableInput) {
      contactObject = {
        id,
        name,
        address,
        organization,
        company_id,
        phone: phone.replace(ddi, ''),
        ddi,
        email,
        cpf,
        // observation,
        operator: operatorInteger,
        channels,
      };
    } else {
      contactObject = {
        id,
        name,
        address,
        organization,
        company_id,
        phone: phone.replace(ddi, ''),
        ddi,
        email,
        cpf,
        // observation,
        operator: operatorInteger,
        channelPhone,
      };
    }
    updateContactRequest(
      {
        ...contactObject,
      },
      true
    );
    setIsLoadingDelay(true);
    //setIsLoading(false);
  };

  function handleVerifyContactBeforeSave() {
    if (contactActionDebug) {
      console.info(
        `🚀 ~ file: index.js:227 ~ handleVerifyContactBeforeSave ~ action triggered by user ${userConfig.name} to ${props.type} contact named ${name}`
      );
    }

    if (isLoading) {
      if (contactActionDebug) {
        console.info(
          `🚀 ~ file: index.js:232 ~ handleVerifyContactBeforeSave ~ user should wait until delay is done`
        );
      }
      return;
    }

    setIsLoading(true);

    const CONTACT_FUNCTIONS = {
      add: submitSaveContact,
      edit: submitEditContact,
    };

    //verify form fields before start add or save contact
    if (
      (props.type === 'add' &&
        !(name !== '' && phone !== '' && channelPhone !== '')) ||
      (props.type === 'edit' && !(name !== '' && channels !== ''))
    ) {
      toast.error(
        <div>
          <strong>Erro ao Enviar!</strong>
          <br /> Preencha os campos obrigatórios:{' '}
          <strong>Nome, Telefone e Canal WhatsApp.</strong> <br />
        </div>
      );

      setIsLoading(false);
      return;
    }
    // verify phone length before add or edit contact
    let PHONE_NUMBER_MAX_LENGTH = 13;
    if (phone.length > PHONE_NUMBER_MAX_LENGTH) {
      toast.error(
        <div className="toasttest">
          <strong>Erro ao Enviar!</strong>
          <br />
          Número inválido
        </div>
      );
      setIsLoading(false);
      return;
    }

    CONTACT_FUNCTIONS[props.type]();
  }

  useEffect(() => {
    if (props.type === 'edit' && contactToUpdate) {
      // Objeto que enviará dados dos canais quando for editar algum dos canais
      if (contactToUpdate.externals) {
        contactToUpdate.externals.map((external) => {
          external.type = 'whatsapp';
          delete external.updated_at;
          delete external.created_at;
          external.uid = external.uid.split(/[@]/i)[0]; // retira o @c.us e usa apenas a parte do telefone
          return null;
        });
      }

      setPhone(contactToUpdate.phone);
      setId(contactToUpdate.id);
      setName(contactToUpdate.name);
      setOrganization(contactToUpdate.organization);
      setAddress(contactToUpdate.address);
      setEmail(contactToUpdate.email);
      setOperator(contactToUpdate.operator);
      setCpf(contactToUpdate.cpf);
      setChannels(contactToUpdate.externals);
    }

    if (props.type === 'add' && contactToAdd) {
      const extractedDDI = extractDialCode(contactToAdd.phone);      
      setDDI(extractedDDI);
      setPhone(contactToAdd?.phone || '');
      setName(contactToAdd?.name || '');
      setEmail(contactToAdd?.email || '');
      setChannelPhone(contactToAdd.phone);
    }
  }, [contactToAdd, contactToUpdate, props]);

  useEffect(() => {
    fetchCompaniesList();
  }, []);

  useEffect(() => {
    const companyId = selectedCompany?.value || null;
    setCompanyId(companyId);
  }, [selectedCompany]);

  if (props.type === 'add') {
    contactObject = {
      name,
      phone,
      ddi,
      email,
      cpf,
      address,
      organization,
      company_id,
      channels: [
        {
          type: 'whatsapp',
          uid: channelPhone,
        },
      ],
      // observation,
      operator,
      picture: checkAvatar(name, ''),
    };
  } else if (props.type === 'edit') {
    contactObject = { ...contactToUpdate };
    contactObject.channels = contactObject.channels || [
      {
        type: 'whatsapp',
        uid: channelPhone,
      },
    ];
  }

  /*
  useEffect(() => {
    if (props.type === 'edit') {
      setPhone(contactToUpdate.phone);
      setId(contactToUpdate.id);
      setName(contactToUpdate.name);
      setEmail(contactToUpdate.email);
      setOperator(contactToUpdate.operator);
      setCpf(contactToUpdate.cpf);
      setChannels(contactToUpdate.externals);
      // setChannelPhone(contactToUpdate.phone);
    }
  }, [contactToUpdate, props]);
*/
  // Responsáveis por colocar máscaras dentro do valor do input
  const handleChangePhoneNumber = useCallback(
    (value, country) => {
      setPhone(value);
      setDDI(country.dialCode);
    },
    [setPhone, setDDI]
  );
  const handleChangeChannelPhone = useCallback(
    (value) => setChannelPhone(value),
    [setChannelPhone]
  );

  // Função que valida se o canal terá nono digito
  const validateChannelNineDigit = (ddiNumber, phone, editingChannel) => {
    if (ddiNumber != 55) {
      return null;
    }
    // Convertendo em string
    phone = `${phone}`;
    let newPhone;

    const digitNinePermitted = [
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
      29,
    ];

    let ddi = phone.substring(0, 2);

    newPhone = phone.replace(ddi, '');

    const ddd = newPhone.substring(0, 2);

    newPhone = newPhone.replace(ddd, '');

    const hasNineDigit = digitNinePermitted.findIndex(
      (element) => element == ddd
    );

    // Deveria ter o  nono digito, pq está na lista
    if (hasNineDigit != -1) {
      if (
        newPhone.length < 9 &&
        (newPhone.substring(0, 1) == 8 || newPhone.substring(0, 1) == 9)
      ) {
        newPhone = `9${newPhone}`;
      }
    } else {
      // Não deveria ter nono digito, mas o telefone tem, então retiramos.
      if (newPhone.length === 9) {
        newPhone = newPhone.replace('9', '');
      }
    }
    if (editingChannel) {
      return `${ddi}${ddd}${newPhone}`;
    }
    setChannelPhone(`${ddi}${ddd}${newPhone}`);
  };

  function handleClickClose() {
    if (props.type === 'edit') {
      setOpenEditContact(false);
      openChatTab();
    } else if (props.type === 'add') {
      setOpenAddContact(false);

      if (
        isMobile &&
        document
          .querySelector('.chatPanelContainer')
          .classList.contains('hidden')
      ) {
        document
          .querySelector('.chatPanelContainer')
          ?.classList.remove('hidden');
        document
          .querySelector('.contactPanelMobile')
          ?.classList.remove('hidden');
        document.querySelector('.topMobile')?.classList.remove('hidden');
        document
          .querySelector('.topNavigationContainer')
          ?.classList.remove('hidden');
      }

      if (contactToAdd) {
        unsetAddCardContact();
        openChatTab();
      }
    }
  }

  return (
    <>
      <Form>
        {isMobile && (
          <div className="titleAddContactMobile teste">
            <h2>
              {props.type === 'add' ? 'Adicionar Contato' : 'Editar Contato'}
            </h2>
            <div onClick={() => handleClickClose()} className="closeAddContact">
              <Close className="closeAddContactIcon" width={16} height={16} />
            </div>
          </div>
        )}
        <Container
          className={
            'contactFormBody ' + (id && id > 0 ? 'editForm' : 'createForm')
          }
        >
          {companiesListLoading ? (
            <>
              {errorFoundCompany ? (
                <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                  <h3>Erro ao achar empresas, tente novamente.</h3>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => {
                      fetchCompaniesList();
                      setErrorFoundCompany(false);
                    }}
                  >
                    Tentar novamente
                  </Button>
                </div>
              ) : (
                <Loading size="sm" text="Carregando empresas..." />
              )}
            </>
          ) : (
            <>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="name">Nome:</Label>
                    <Input
                      className="f-control"
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="phoneNumber">Telefone com DDD:</Label>
                    {props.type === 'add' ? (
                      <PhoneInput
                        country={'br'}
                        value={phone}
                        // Autoformat false => apenas para numeros mexicanos e argentinos
                        autoFormat={true}
                        enableLongNumbers={true}
                        onChange={handleChangePhoneNumber}
                        placeholder="+55 (62) 9999-9999"
                        onBlur={() =>
                          ddi === '55'
                            ? validateChannelNineDigit(ddi, phone)
                            : setChannelPhone(phone)
                        }
                      />
                    ) : (
                      <PhoneInput
                        country={'br'}
                        value={phone}
                        onChange={handleChangePhoneNumber}
                        // Autoformat false => apenas para numeros mexicanos e argentinos
                        autoFormat={true}
                        enableLongNumbers={true}
                        placeholder="+55 (62) 9999-5555"
                      />
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="email">E-mail:</Label>
                    <Input
                      className="f-control"
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="cpf">CPF </Label>
                    <Input
                      className="f-control"
                      type="text"
                      name="cpf"
                      id="cpf"
                      value={cpf}
                      onChange={(e) => {
                        const cpfMasked = cpfMask(e.target.value);
                        setCpf(cpfMasked);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="address">Endereço:</Label>
                    <Input
                      className="f-control"
                      type="text"
                      name="address"
                      id="address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="organization">Empresa: </Label>
                    {!customerHasCrm && !isWl ? (
                      <>
                        <UncontrolledTooltip
                          placement="down"
                          target="edit-company-dropdown"
                        >
                          O acesso à edição do campo "Empresa" está disponível
                          apenas para contas com permissões de CRM ativas.
                        </UncontrolledTooltip>
                        <Icon
                          icon="poli-icon pi-info-fill infoChannelIcon"
                          id="edit-company-dropdown"
                        />
                      </>
                    ) : null}
                    <ReactSelect
                      className="f-control"
                      options={companiesList}
                      isDisabled={disabledCompanySelector}
                      isClearable={true}
                      isSearchable={true}
                      styles={selectStyles}
                      defaultValue={selectedCompany}
                      placeholder={companySelectorPlaceholder}
                      value={selectedCompany}
                      name="organization"
                      onChange={(selectedOption) => {
                        setSelectedCompany(selectedOption);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="organization">Observações:</Label>
                    <Input
                      className="form-control"
                      type="textarea"
                      name="organization"
                      id="organization"
                      style={{ height: '100px', overflowY: 'auto' }}
                      value={organization}
                      onChange={handleObservationChange}
                    />
                  </FormGroup>
                  <div
                    style={{
                      fontSize: '12px',
                      color:
                        organization?.length > charLimit ? 'red' : 'inherit',
                      marginTop: '-15px',
                      marginBottom: '10px',
                    }}
                  >
                    {charLimit - observationLength} caracteres restantes.
                  </div>
                </Col>
              </Row>

              {superRole === 'adm' || superRole === 'manager' ? (
                <Row form>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="selectOperator">Atendente:</Label>
                      <Input
                        key={operator}
                        className="f-control"
                        type="select"
                        name="select"
                        id="selectOperator"
                        value={operator || ''}
                        onChange={(e) => {
                          setOperator(e.target.value);
                        }}
                      >
                        <option>Selecione o Operador</option>
                        {users
                          ? users.map((user) => {
                              if (
                                user.roles &&
                                user.roles.length > 0 &&
                                user.roles.some((el) => el.name === 'lead') ===
                                  false
                              ) {
                                return (
                                  <option
                                    id={user.id}
                                    key={user.id}
                                    value={user.id}
                                  >
                                    {user.name} - {''}
                                    {user.available_service !== 1
                                      ? 'Indisponível'
                                      : user.status === 1
                                      ? 'Online'
                                      : 'Offline'}
                                  </option>
                                );
                              }
                            })
                          : ''}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              <div className="channelPhoneContainer">
                <h3>Canais do Contato</h3>
                <Row form>
                  {props.type === 'add' || !channels || !channels.length ? (
                    // Se o tipo do formulário for de adicionar Contato
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="channelPhone">WhatsApp com DDD: </Label>
                        <PhoneInput
                          country={'br'}
                          value={channelPhone}
                          onChange={handleChangeChannelPhone}
                          id="channelPhone"
                          // Autoformat false => apenas para numeros mexicanos
                          autoFormat={true}
                          enableLongNumbers={true}
                          placeholder="+55 (62) 9999-9999"
                          onBlur={() =>
                            ddi === '55'
                              ? validateChannelNineDigit(ddi, channelPhone)
                              : ''
                          }
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    // Se o tipo do formulário for de Editar Contato
                    <>
                      {channels.map((channel, index) => {
                        // Se o canal for whatsapp
                        if (
                          channel.channel_id === 1 ||
                          channel.channel_id === 6
                        ) {
                          let newArrChannels = [...channels];
                          return (
                            <Col lg={6} key={index} id={channel}>
                              <FormGroup>
                                <InputGroup>
                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="input-group-channel-icon"
                                  >
                                    <InputGroupText>
                                      {setIconChannel(1)}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    key={channel.id}
                                    value={channel.uid}
                                    onChange={(e) => {
                                      // Substituindo o uid pelo que está sendo alterado
                                      newArrChannels[index].uid =
                                        e.target.value;
                                      setChannels(newArrChannels); // Colocando o novo array no estado
                                    }}
                                    onBlur={() => {
                                      if (ddi === '55') {
                                        const validNumber =
                                          validateChannelNineDigit(
                                            ddi,
                                            `${newArrChannels[index].uid}`,
                                            true
                                          );

                                        if (
                                          `${newArrChannels[index].uid}`
                                            .length === 12 ||
                                          `${newArrChannels[index].uid}`
                                            .length === 13
                                        ) {
                                          newArrChannels[index].uid =
                                            validNumber;
                                          setChannels(newArrChannels);
                                        } else {
                                          toast.error(
                                            'Este número não pode ser um canal. Tente novamente e tenha cuidado ao salvar um contato inválido, este processo é irreversível.'
                                          );
                                        }
                                      }
                                    }}
                                    placeholder="Ex. 55 62 9999-9999"
                                    disabled={disableInput}
                                  />
                                  {superRole === 'adm' ||
                                  superRole === 'manager' ||
                                  superRole === 'supervisor' ? (
                                    <InputGroupAddon
                                      addonType="prepend"
                                      className="input-group-delete-channel-button"
                                    >
                                      <Button
                                        onClick={() => {
                                          setExternalSelectedToDelete(channel);
                                          setDeleteExternalModal(
                                            !deleteExternalModal
                                          );
                                        }}
                                      >
                                        <Icon
                                          icon="poli-icon pi-delete-fill"
                                          color="var(--white-font-color)"
                                          size={20}
                                        />
                                      </Button>
                                    </InputGroupAddon>
                                  ) : (
                                    ''
                                  )}

                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="input-group-edit-channel-button"
                                  >
                                    <Button
                                      onClick={() => setEditModal(!editModal)}
                                    >
                                      <Icon
                                        icon="poli-icon pi-pencil-fill"
                                        color="var(--white-font-color)"
                                        size={20}
                                      />
                                    </Button>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>

                              <Modal
                                isOpen={editModal}
                                toggle={() => setEditModal(!editModal)}
                                className={
                                  localStorage.getItem('@polichat/darkmode') ===
                                  'true'
                                    ? 'darkMode'
                                    : ''
                                }
                              >
                                <ModalHeader
                                  toggle={() => setEditModal(!editModal)}
                                >
                                  <Icon
                                    icon="poli-icon pi-alert-fill"
                                    color="#FFA26B"
                                    size={50}
                                  />
                                  <span right="true">Alteração de Canal</span>
                                </ModalHeader>
                                <ModalBody>
                                  Ao editar este canal você irá alterar o número
                                  com o qual conversa com este contato. Esta
                                  ação poderá desconectar suas conversas e é{' '}
                                  <strong>irreversível.</strong>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    size="lg"
                                    color="info"
                                    onClick={() => {
                                      if (disableInput === true) {
                                        setDisableInput(false);
                                      }
                                      setEditModal(!editModal);
                                    }}
                                  >
                                    Quero Editar
                                  </Button>{' '}
                                  <Button
                                    size="lg"
                                    color="danger"
                                    onClick={() => setEditModal(!editModal)}
                                  >
                                    Cancelar
                                  </Button>
                                </ModalFooter>
                              </Modal>

                              {/* End Edit Modal */}

                              <Modal
                                isOpen={deleteExternalModal}
                                toggle={() =>
                                  setDeleteExternalModal(!deleteExternalModal)
                                }
                                className={
                                  localStorage.getItem('@polichat/darkmode') ===
                                  'true'
                                    ? 'darkMode'
                                    : ''
                                }
                              >
                                <ModalHeader
                                  toggle={() =>
                                    setDeleteExternalModal(!deleteExternalModal)
                                  }
                                >
                                  <Icon
                                    icon="poli-icon pi-alert-fill"
                                    color="#FFA26B"
                                    size={50}
                                  />
                                  <span right="true">
                                    Deletar canal deste contato
                                  </span>
                                </ModalHeader>
                                <ModalBody className="externalDeleteContainer">
                                  Tem certeza que deseja deletar este canal do
                                  whatsapp deste contato?
                                  <strong>
                                    Esta ação não poderá ser desfeita!
                                  </strong>
                                  <div className="info-channel-to-delete">
                                    <span>Nome: {name}</span>
                                    <span>
                                      Contato a ser deletado:{' '}
                                      {externalSelectedToDelete.name}
                                    </span>
                                  </div>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    size="lg"
                                    color="info"
                                    onClick={() => {
                                      handleDeleteExternal(
                                        externalSelectedToDelete
                                      );
                                      setDeleteExternalModal(
                                        !deleteExternalModal
                                      );
                                    }}
                                  >
                                    Sim, tenho certeza!
                                  </Button>{' '}
                                  <Button
                                    size="lg"
                                    color="danger"
                                    onClick={() =>
                                      setDeleteExternalModal(
                                        !deleteExternalModal
                                      )
                                    }
                                  >
                                    Cancelar
                                  </Button>
                                </ModalFooter>
                              </Modal>
                            </Col>
                          );
                        } else {
                          return (
                            <Col lg={6}>
                              <FormGroup>
                                <InputGroup>
                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="input-group-edit-channel-icon"
                                  >
                                    <InputGroupText>
                                      {setIconChannel(channel.channel_id)}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    key={channel.id}
                                    type="text"
                                    className="f-control"
                                    value={channel.uid}
                                    disabled={true}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          );
                        }
                      })}
                    </>
                  )}
                </Row>
              </div>
            </>
          )}
        </Container>
        <Container className="contactFormFooter">
          <Row className="buttonGroup center">
            <Col sm={12}>
              {isSavingContact ? (
                <Loading size="sm" text="Salvando alterações ..." />
              ) : (
                <>
                  <Button
                    size="lg"
                    color="danger"
                    onClick={() => handleClickClose()}
                  >
                    {companiesListLoading ? 'Voltar' : 'Cancelar'}
                  </Button>{' '}
                  {!companiesListLoading && (
                    <Button
                      size="lg"
                      color="primary"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleVerifyContactBeforeSave();
                      }}
                    >
                      Salvar
                    </Button>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Form>
      <ModalMessage
        isOpen={showRestoreContactModal}
        toggle={() => handleShowRestoreContactModal()}
        handleSubmit={() => {
          restoreContact(customerId, deletedContactId);
        }}
        title="Restaurar contato"
        subtitle="Esse contato já existe e está na lixeira. Deseja restaurá-lo?"
        buttonTitle="Restaurar contato"
      />
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  chat: state.chat,
  users: state.chat.config.general?.users,
  customerId: state.general?.current_customer_id,
  currentChatSelected: state?.chat?.chatSelectedId,
  contactToUpdate: state.chat?.contacts?.currentContactToUpdate,
  contactToAdd: state.chat?.contacts?.currentContactToAdd,
  props: ownProps,
  userConfig: state?.general?.user,
  isSavingContact: state.chat?.isSavingContact,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
