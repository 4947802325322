import React, { useState, useRef, useEffect } from 'react';
import { Button, Label, FormGroup, CustomInput } from '@polichat/flamboyant';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';
import Animation from '../../../../common/animation';
import TransferImage from './src/TransferImage';
import {
  Icon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Badge,
} from '@polichat/flamboyant';

import {
  handleMergeDepartmentToUsers,
  removeAccentString,
} from '../../../../../store/modules/chat/functions';

import InputSearch from '../../../../common/form/InputSearch';

import Avatar from '../../../../common/profile/Avatar';
import checkAvatar from '../../../../../utils/checkAvatar';

import ClickOutsideEvent from '../../../../../events/ClickOutsideEvent';

// Array de formatação dos DropItems
let usersToShow = [];

var pageFull = 1;
var loading = false;

function TransferChatModal({
  chat,
  transferChatRequest,
  closeTransferChatModal,
  allOperators,
  allDepartments,
  currentUser,
}) {
  const [observerScroll, setObserverScroll] = useState(false);
  const [loadingListTop, setLoadingListTop] = useState(false);
  const [loadingListDown, setLoadingListDown] = useState(false);
  const limitePage = 4;

  const sortByName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  // Ordenando por nome
  const allOperatorsSort = allOperators?.sort(sortByName);
  let usersOriginal = allOperatorsSort;
  const [users, setUsers] = useState(usersOriginal);

  /**
   * Bloquear encaminhar contatos para atendentes offline e indisponíveis
   * blocked_redirect tive true no banco de dados
   */
  useEffect(() => {
    const blockedRedirect = currentUser && currentUser?.blocked_redirect == 1;

    if (blockedRedirect) {
      usersOriginal = allOperatorsSort.filter(
        (e) => e.available_service == 1 && e.status == 1
      );
      setUsers(usersOriginal);
    }
  }, [allOperatorsSort]);
  /* End */

  let departmentsMergedWithUsers = [];

  /**
   * Criando o array que contem departamento e usuarios separados
   * Apenas se existirem departamentos na empresa
   */
  useEffect(() => {
    if (allDepartments && allDepartments.length > 0 && users && users.length) {
      departmentsMergedWithUsers = handleMergeDepartmentToUsers(
        allDepartments,
        users
      );
    }
  }, [allDepartments?.length, users?.length]);
  /* End */

  /**
   * Monitora o scroll da lista de transferencia
   */
  useEffect(() => {
    const ref = document.getElementById('listTransferScroll');
    if (ref && usersToShow?.length > 0 && observerScroll) {
      ref.addEventListener('scroll', (evt) => {
        let sizeHeightContainer = ref.offsetHeight; // tamanho do container visível para o usuário
        let sizeHeightContainerScroll = evt.target.scrollHeight; // tamanho do container inteiro de mensagens
        let scrollTop = evt.target.scrollTop; // quantidade de px foram rolados em uma transição

        const topPage = scrollTop === 0;
        const endPage =
          scrollTop + sizeHeightContainer >= sizeHeightContainerScroll;

        if (topPage) goPage(true);
        if (endPage) goPage();
      });
    }
  }, [observerScroll, usersToShow?.length]);

  /**
   * Encrementa mais uma pagina e coloca loading para nao ter loop infinito
   */
  const goPage = (top) => {
    if (!loading) {
      /**
       * Logica subir
       */
      if (top && pageFull >= limitePage) {
        loadingListPage(top);
        pageFull -= 1;

        setTimeout(() => {
          const ref = document.getElementById('listTransferScroll');
          if (ref) ref.scrollTop = 100;
        }, 600);
      }

      /**
       * Logicar descer
       */
      if (!top && pageFull < usersToShow?.length) {
        loadingListPage();
        pageFull += 1;
      }
    }
  };

  const loadingListPage = (top) => {
    if (top) setLoadingListTop(true);
    else setLoadingListDown(true);

    loading = true;

    setTimeout(() => {
      if (top) setLoadingListTop(false);
      else setLoadingListDown(false);

      loading = false;
    }, 500);
  };
  /* End */

  /**
   * Montando a lista
   */
  useEffect(() => {
    /**
     * Redefinindo as configurações ao abrir modal
     */
    usersToShow = [];
    pageFull = 1;
    loading = false;
    const perPage = 25;

    /**
     * Scrool ficava no meio
     */
    const ref = document.getElementById('listTransferScroll');
    if (ref) ref.scrollTop = 0;

    if (
      departmentsMergedWithUsers &&
      departmentsMergedWithUsers.length > 0 &&
      allDepartments &&
      allDepartments.length > 0
    ) {
      const render = [];

      /**
       * Departamentos com seus usuarios
       */
      departmentsMergedWithUsers.map((element, counterFiltered) => {
        /**
         * Montando componente
         * Departamento
         */
        render.push(
          <React.Fragment
            key={`department-name-${element.department.id}-${counterFiltered}`}
          >
            {element.department.usersWithoutDept && (
              <div>
                <span
                  className="departmentDropdown transfer-chat-margin"
                  title="Agora todos os atendentes sem departamento, ficam aqui. Gostou da novidade?"
                >
                  <Badge color="warning" pill className="text-white">
                    Novo
                  </Badge>
                </span>
              </div>
            )}

            <span
              className="departmentDropdown transfer-chat-margin"
              title={
                element.department.usersWithoutDept
                  ? 'Todos os atendentes que não tem departamentos'
                  : ''
              }
            >
              {element.department.name}
            </span>
            <DropdownItem divider></DropdownItem>
          </React.Fragment>
        );

        /**
         * Se Departamento nao tiver usuario
         */
        if (element?.users?.length === 0) {
          render.push(
            <p
              className="transfers-name text-center"
              key={`user-not-exist-${element.department.id}-${counterFiltered}`}
            >
              --
            </p>
          );
        }

        /**
         * Montando componente
         * Usuarios do departamento
         */
        element?.users?.sort(sortByName).map((user, index) => {
          render.push(
            <DropdownItem
              key={`list-${user.id}+${counterFiltered}-${index}`}
              className="transfer-options-names"
              value={user.id}
              onClick={() => {
                setOperator(user.id);
                if (element.department.id) setDepartment(element.department.id);
              }}
            >
              <Avatar
                className="operator"
                title={user.name}
                url={checkAvatar(user.name, user.picture)}
                size={23}
                extraClass="transfer-options-avatar"
              />{' '}
              <span className="transfers-name">{user.name}</span> {''}
              {statusUserList(user)}
            </DropdownItem>
          );
        });
        /* End - Montando componente */
      });
      /* End - Usuarios do departamento */

      /**
       * Dividindo Array para nao carregar a lista de uma vez;
       */
      usersToShow = new Array(Math.ceil(render.length / perPage))
        .fill()
        .map((_) => render.splice(0, perPage));
    } else if (users && users.length) {
      /**
       * Caso a empresa não tenha departamentos
       * Necessário possuir usuários
       */
      /**
       * Dividindo Array para nao carregar a lista de uma vez;
       */
      const _users = [...users];
      const _usersArrayList = new Array(Math.ceil(_users.length / perPage))
        .fill()
        .map((_) => _users.splice(0, perPage));

      /**
       * Montando componente
       * Usuarios
       */
      _usersArrayList.map((ele, key) => {
        const render = ele.map((user, index) => {
          return (
            <DropdownItem
              key={`key${key}-index${index}-user${user.id}`}
              value={user.id}
              className="dropItem-filters"
              onClick={(e) => setOperator(e.target.value)}
            >
              <Avatar
                className="operator"
                title={user.name}
                url={checkAvatar(user.name, user.picture)}
                size={23}
                extraClass="transfer-options-avatar"
              />

              {user.name.split(' ')[0]}

              {statusUserList(user)}
            </DropdownItem>
          );
        });

        usersToShow.push(render);
      });
      /* End - Montando componente */
    }
    /* End - Caso a empresa não tenha departamentos */
  }, [
    departmentsMergedWithUsers?.length,
    allDepartments?.length,
    users,
  ]);

  const statusUserList = (user) => {
    return (
      <>
        {user.available_service !== 1 && user.status === -1 ? (
          <>
            <Icon
              id="indisponivel"
              icon={'poli-icon pi-point-fill'}
              size={15}
            />
            <UncontrolledTooltip placement="top" target="indisponivel">
              Indisponível
            </UncontrolledTooltip>
          </>
        ) : user.status === 1 ? (
          <>
            <Icon id="online" icon={'poli-icon pi-point-fill'} size={15} />
            <UncontrolledTooltip placement="top" target="online">
              {'Disponível'}
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <Icon id="offline" icon={'poli-icon pi-point-fill'} size={15} />
            <UncontrolledTooltip placement="top" target="offline">
              {'Desconectado'}
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };

  /* end - Montando a lista */

  const wrapperRef = useRef(null);

  ClickOutsideEvent(wrapperRef, closeTransferChatModal);

  const opennedChat = chat?.chats?.find((chat) => chat.open === true);
  const isFetchingData =
    usersToShow?.length > 0 ? false : opennedChat.transfer_chat_modal_loading;

  const showWalletOptions = chat?.config?.general?.has_wallet === 1;
  //PS-961
  const [selectedOperator, setOperator] = useState(null);
  /*
  const [selectedOperator, setOperator] = useState(
    users?.length ? users[0].id : null
  );
  */
  //PS-961

  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedDepartment, setDepartment] = useState(null);

  const [walletOption, setWalletOption] = useState(
    showWalletOptions ? 0 : null
  );

  /**
   * O que fazer se alterar selectedOperator
   */
  useEffect(() => {
    // Resetando scrool ao selecionar um operador
    setObserverScroll(false);
    pageFull = 1;

    // Usuario Selecionado para mostrar no label do input
    if (usersOriginal && usersOriginal.length > 0) {
      const foundUser = usersOriginal.find((e) => e.id == selectedOperator);
      if (foundUser) setSelectedUserName(foundUser.name);
    }
  }, [selectedOperator]);
  /* End */

  const onTransferChatRequest = () => {
    let wallet = [];

    if (walletOption === 0 || walletOption === null) {
      wallet.push(selectedOperator);
    } else if (walletOption === 1) {
      wallet.push(selectedOperator);
      if (opennedChat.chat?.origin_id) wallet.push(opennedChat.chat.origin_id);
    } else if (walletOption === 2) {
      wallet = [];
    }

    let data = {
      operator_id: selectedOperator,
      contact_id: opennedChat.id,
      department_id: selectedDepartment,
      wallet: wallet,
    };

    transferChatRequest(data);
  };

  const transferButton = opennedChat?.transfering ? (
    <Animation icon="loading" size={'tiny'} />
  ) : (
    <Button
      className="transfer-chat-button-yes"
      onClick={() => {
        onTransferChatRequest();
      }}
    >
      Encaminhar
    </Button>
  );

  /**
   * Campo de pesquisa e toda a logica de pesquisa do atendente
   */
  const [currentValueSearch, setCurrentValueSearch] = useState('');
  const [lastTimeout, setLastTimeout] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const onUpdateSearchValue = (value) => {
    setLoadingSearch(true);
    setUsers([]);

    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }

    setCurrentValueSearch(value);

    setLastTimeout(
      setTimeout(() => {
        setLoadingSearch(false);
        searchList(value);
      }, 1000)
    );
  };

  const searchList = (value = '') => {
    if (usersOriginal && usersOriginal.length) {
      const result = usersOriginal.filter((item) => {
        return removeAccentString(item.name.toLowerCase()).includes(
          removeAccentString(value?.toLowerCase())
        );
      });

      setUsers(result);
      loadingListPage();
    }
  };

  const searchRender = (
    <div className="transfer-chat-search">
      <InputSearch
        onChange={(e) => onUpdateSearchValue(e.target.value)}
        value={currentValueSearch}
        loading={loadingSearch}
        placeholder=""
        clearSearch={onUpdateSearchValue}
      />
    </div>
  );

  const notUsersRender = () => {
    if (loadingSearch) {
      return (
        <p className="text-center">
          <small>Buscando...</small>
        </p>
      );
    }

    if (users && users.length > 0) {
      return '';
    } else if (currentValueSearch?.toString()?.length > 0) {
      return (
        <p className="text-center">
          <small>Nenhum resuldado encontrado!</small>
        </p>
      );
    } else {
      return (
        <p className="text-center">
          <small>Não existem atendentes cadastrados.</small>
        </p>
      );
    }
  };
  /* End - Search */

  const userAndWalletData = isFetchingData ? (
    <div className="transfer-chat-loading">
      <small className="transfer-chat-loading-small">
        {' '}
        Aguarde enquanto carregamos as informações...
      </small>
      <Animation icon="loading" size={'tiny'} />
    </div>
  ) : (
    <>
      <div className="transfer-chat-subsection operators">
        <FormGroup className="transfer-chat-subgroup">
          <Label for="operators" className="transfer-chat-label">
            Atendentes:
          </Label>
          <UncontrolledDropdown className="transf-chats">
            <DropdownToggle
              color="neutral"
              caret
              id="operators"
              name="operators"
              className="transfer-option-name total"
              onClick={(e) => {
                setObserverScroll(true);
              }}
              onChange={(e) => {
                if (e.target.value) {
                  setOperator(parseInt(e.target.value));
                }
              }}
            >
              <span className="nameoperator">
                {' '}
                {selectedUserName && selectedUserName.toString()?.length > 0
                  ? selectedUserName
                  : 'Selecionar'}
              </span>
            </DropdownToggle>
            <DropdownMenu>
              {searchRender}
              {notUsersRender()}

              <div className="transfer-chats-dropdown" id="listTransferScroll">
                {loadingListTop && <Animation icon="loading" size={'tiny'} />}

                {/* Renderizazando lista de acordo com scroll */}
                {usersToShow.map((e, i) => {
                  if (i > pageFull - limitePage && i < pageFull) return e;
                })}

                {loadingListDown && <Animation icon="loading" size={'tiny'} />}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </FormGroup>
      </div>

      {showWalletOptions && (
        <div className="transfer-chat-subsection wallet">
          <FormGroup className="transfer-chat-subgroup">
            <Label for="wallet" className="transfer-chat-label">
              Redirecionamento de carteira:
            </Label>

            <FormGroup className="form-check-custom" check>
              <Label check for="wallet0"></Label>
              <CustomInput
                className="transfer-chat-label-custom"
                label="Para a carteira do usuário"
                id="wallet0"
                type="radio"
                checked={
                  walletOption === '0' ||
                  walletOption === null ||
                  walletOption === 0
                }
                onChange={(e) => {
                  if (e.target.value) setWalletOption(parseInt(e.target.value));
                }}
                name="wallet"
                value="0"
              />
            </FormGroup>

            <FormGroup className="form-check-custom" check>
              <Label check for="wallet1"></Label>

              <CustomInput
                className="transfer-chat-label-custom"
                label="Duplicar na carteira do usuário"
                id="wallet1"
                type="radio"
                name="wallet"
                value="1"
                checked={walletOption === '1'}
                onChange={(e) => {
                  if (e.target.value) setWalletOption(parseInt(e.target.value));
                }}
              />
            </FormGroup>

            <FormGroup className="form-check-custom" check>
              <Label check for="wallet2"></Label>

              <CustomInput
                className="transfer-chat-label-custom"
                label="Não mover a carteira"
                id="wallet2"
                type="radio"
                name="wallet"
                value="2"
                checked={walletOption === '2'}
                onChange={(e) => {
                  if (e.target.value) setWalletOption(parseInt(e.target.value));
                }}
              />
            </FormGroup>
          </FormGroup>
        </div>
      )}

      <div className="transfer-chat-buttons-subsection">{transferButton}</div>
    </>
  );

  return (
    <div
      className="transfer-chat-modal"
      id="transfer-chat-modal"
      ref={wrapperRef}
    >
      <div className="transfer-chat-subsection">
        <TransferImage />
      </div>

      <div className="transfer-chat-subsection-heading">
        <Icon
          icon="poli-icon pi-talks-line"
          color={'var(--green-poli)'}
          size={20}
        />
        <span className="transfer-chat-title">Encaminhar Chat</span>
      </div>

      {userAndWalletData}
    </div>
  );
}

const mapStateToProps = (state) => ({
  chat: state.chat,
  currentUser: state.chat?.config?.user,
  allOperators: state.chat?.config?.general?.users,
  allDepartments: state.chat?.config?.general?.departments,
  current_customer_id: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransferChatModal);
