// react
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
// ui
import { Button, Icon, Row, Col } from '@polichat/flamboyant';
import Animation from '../../../../../common/animation';
// types
import { ChatStore } from './types/store';
import { QuickMessage, QuickMessageSelectorProps } from './types/types';
import {
  CSSHeightValue,
  KeyboardCodes,
  QuickTypes,
  QuickTypesText,
} from './types/constants';
import { MenuRootRef } from './components/Fields/MenuFilters/Root';

// components
import { EmptyResult } from './components/EmptyResult';
import { QuickItem } from './components/QuickItem/index';
import { MenuFilters } from './components/Fields/MenuFilters/index';

import { MenuTypeSelector } from './components/Fields/MenuTypeSelector';
import { MenuDepartmentSelector } from './components/Fields/MenuDepartmentSelector';
import { QuickSearchTerm } from './components/Fields/QuickSearchTerm';

import { AddQuickMessageButton } from './components/Modal/AddQuickMessageButton';
import { ModalQuickMessageForm } from './components/Modal/ModalQuickMessageForm';
import { ModalRemoveQuickMessage } from './components/Modal/ModalRemoveQuickMessage';
import { ModalMessagePreview } from './components/Modal/ModalMessagePreview';
// custom hooks
import { useModalState } from './hooks/useModalsState';
import { useFilterQuickMessage } from './hooks/useFilterQuickMessage';
import { useQuickMessage } from './hooks/useQuickMessage';
import { useSearchQuickForm } from './hooks/useSearchQuickForm';
import { useQuickMessageService } from './hooks/useQuickMessageService';
// CSS
import styles from './QuickMessageSelector.module.css';

import { isNumberAPITypeButtonsAndLists, isNumberAPITypeWABA } from './hooks/utils';

type ElementRef = {
  [key: number]: HTMLButtonElement | null;
};

/**
 * https://react.dev/reference/react/memo#minimizing-props-changes
 */
export const QuickMessageSelectorV2 = memo(function QuickMessageSelector_v2({
  showOnlyTemplateOption,
  setSelectedQuickMessage,
}: QuickMessageSelectorProps) {
  /**
   * QuickMessage Redux Store
   * informações de quick_message
   * se está aberto para visualização
   * se está carregando
   * se houve falha na requisição
   */

  const isOpen = useSelector(
    (state: any) => state?.chat?.quickMessages?.isOpen ?? false
  ) as boolean;

  const isLoading = useSelector(
    (state: any) => state?.chat?.quickMessages?.loading ?? false
  ) as boolean;
  const fetchFailure = useSelector(
    (state: any) => state?.chat?.quickMessages?.failure ?? false
  ) as boolean;

  const searchTerm = useSelector(
    (state: any) => state?.chat?.quickMessages?.term ?? ''
  ) as string;

  /**
   * Select Channel > OpenedChat - Redux Store
   * informações sobre o canal selecionado do chat
   */
  const selectedChannel = useSelector((state: any) => {
    const chat =
      state?.chat?.chats?.filter((chat: any) => {
        return chat.open === true;
      })[0] || [];

    return chat?.view?.selectedChannel ?? null;
  }) as ChatStore['view']['selectedChannel'] | null;

  /**
   * User - Redux Store
   * Nome do usuário logado
   * Role (permissão) do usuário logado
   * Id do usuário
   */
  const userRole = useSelector(
    (state: any) => state.chat?.config?.user?.superRole ?? '' ?? ''
  ) as string;
  const userId = useSelector(
    (state: any) => state.chat?.config?.user?.id ?? null
  ) as number | null;

  // Navegação com as setinhas
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const itemRef = useRef<ElementRef>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentScroll = useRef({
    scrollTop: 0,
    scrollBottom: CSSHeightValue.QUICK_MESSAGE_LIST_HEIGHT,
  });

  /**
   * Custom Hook - Form
   * Hook para controlar a selecão do tipo de mensagem e o termo de busca
   * watch -> está escutando qualquer mudança de valor do campo do formulário
   */
  const form = useSearchQuickForm();

  const watchText = useWatch({
    control: form.control,
    name: 'text',
  });
  const watchType = useWatch({
    control: form.control,
    name: 'type',
  });
  const watchDepartment = useWatch({
    control: form.control,
    name: 'department',
  });

  /**
   * Custom Hook - Service
   * Lidar com as requisições de quickmessage via disparo de actions (ciclo Redux-Sagas)
   */
  const { reload } = useQuickMessageService();

  /**
   * Custom Hook - Filter Quick Message
   * lidar com o filtro de mensagens
   */
  const { filteredQuickMessages } = useFilterQuickMessage({
    type: watchType,
    text: watchText,
    channelId: selectedChannel?.id ?? null,
    department_id: watchDepartment ?? null,
    term: searchTerm,
  });

  /**
   * Custom Hook - Modal State
   * lidar com o estado dos modais de criar/editar/excluir uma mensagem rápida
   * exibir o preview de um template ou mensagem com botões
   */
  const {
    isOpenPreview,
    handleOpenPreviewModal,
    isOpenDelete,
    handleOpenDeleteModal,
    isOpenEdit,
    handleOpenEditModal,
    handleCloseModal,
    selectedQuickMessage,
    handleSetSelectedQuickMessage,
  } = useModalState();

  /**
   * Custom Hook - helper functions
   * funções auxiliares para exibição de informações legiveis para o usuário
   */
  const { getMessageTextUI, isQuickMessageEditable, getMessageTreated } =
    useQuickMessage();

  /**
   * function handleSendQuickMessage
   * lidar com o tratamento da mensagem escolhida para o componente pai
   * converte o campo TEXT pela string convertida do texto (caso template) e altera os valores de     variáveis
   */
  function handleSendQuickMessage(message: QuickMessage) {
    const result = getMessageTreated(message);
    setSelectedQuickMessage(result);
  }

  /**
   * const isSelectedChannelEnabledToSendTemplate
   * verifica se o canal selecionado permite
   * o envio de mensagem tipo TEMPLATE
   */
  const isSelectedChannelEnabledToSendTemplate = useMemo(() => {
    if (!selectedChannel?.api) return false;
    return isNumberAPITypeWABA(selectedChannel.api);
  }, [selectedChannel?.api]);

    /**
   * const isSelectedChannelEnabledToSendTemplate
   * verifica se o canal selecionado permite
   * o envio de mensagem tipo BUTTONS e LIST
   */
    const isSelectedChannelEnabledToButtonsAndLists = useMemo(() => {
      if (!selectedChannel?.api) return false;
      return isNumberAPITypeButtonsAndLists(selectedChannel.api);
    }, [selectedChannel?.api]);

  /**
   * Funções para lidar com as ações de teclado
   */
  function onScroll(event: React.UIEvent<HTMLDivElement>) {
    currentScroll.current = {
      scrollTop: event.currentTarget.scrollTop,
      scrollBottom:
        event.currentTarget.scrollTop +
        CSSHeightValue.QUICK_MESSAGE_LIST_HEIGHT,
    };
  }

  const handleKeyUpDown = (event: KeyboardEvent) => {
    // Previne que o ArrowUp e ArrowDown aconteça em outras partes da aplicação
    // enquanto o QuickMessage estiver aberto
    if (
      event.key === KeyboardCodes.ARROW_UP ||
      event.key === KeyboardCodes.ARROW_DOWN
    ) {
      event.stopPropagation();
      event.preventDefault();
      const totalItems = filteredQuickMessages.length;

      switch (event.key) {
        case KeyboardCodes.ARROW_UP:
          {
            // Se estiver no topo da lista, não tenta subir mais
            if (selectedItem === 0) return;

            // Define como item selecionado o anterior ao atual
            setSelectedItem((prevItem) => prevItem - 1);

            const elementTop =
              (selectedItem - 2) * CSSHeightValue.QUICK_MESSAGE_ITEM_HEIGHT;

            if (elementTop < currentScroll.current.scrollTop) {
              const prevItem = itemRef.current[selectedItem - 1];
              prevItem && prevItem?.scrollIntoView({ block: 'start' });
            }
          }
          break;
        case KeyboardCodes.ARROW_DOWN:
          {
            // Se estiver no fim da lista, não tenta descer mais
            if (selectedItem === totalItems - 1) return;

            // Define como item selecionado o próximo ao atual
            setSelectedItem((prevItem) => prevItem + 1);

            const elementBottom =
              (selectedItem + 2) * CSSHeightValue.QUICK_MESSAGE_ITEM_HEIGHT;

            if (elementBottom > currentScroll.current.scrollBottom) {
              const nextItem = itemRef.current[selectedItem + 1];
              nextItem && nextItem.scrollIntoView({ block: 'end' });
            }
          }
          break;
        default:
          break;
      }
    }

    if (event.key === KeyboardCodes.ENTER) {
      // Usando o enter para selecinar a mensagem
      event.stopPropagation();
      event.preventDefault();
      handleSendQuickMessage(filteredQuickMessages[selectedItem]);
    }
  };

  /**
   * Hook Effect
   * adicionar o listerEvent para ações do teclado
   * resetar valor do item selecionado para valor inicial
   */
  useEffect(() => {
    if (!isOpen) {
      form.reset({
        type: 'quick',
        text: '',
        department: null,
      });
      setSelectedItem(0);
    } else {
      // Se o component tiver aberto, escuta por ArrowUp e ArrowDown
      window.addEventListener('keydown', handleKeyUpDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyUpDown);
    };
  }, [isOpen, selectedItem, filteredQuickMessages.length]);

  /**
   * Hook Effect
   * resetar o valor do tipo selecionado para **template** quando
   * precisar abrir a janela de 24h do canal do chat
   */
  useEffect(() => {
    if (showOnlyTemplateOption && isOpen) {
      form.reset({
        type: 'template',
        text: '',
        department: null,
      });
    }
  }, [showOnlyTemplateOption, isOpen]);

  const menuFilterRef = useRef<MenuRootRef>(null);

  function handleCloseMenuByRef() {
    menuFilterRef.current?.closeMenu();
  }

  return (
    <React.Fragment>
      {isOpen && (
        <div className={styles.selector_container}>
          {/* Header */}
          <div className={styles.selector_header}>
            <div className={styles.header_text}>
              <Icon
                icon="poli-icon pi-rocket-fill"
                size={20}
                color={'var(--primary)'}
              />
              <span>Escolher {QuickTypesText[watchType || 'quick']}</span>
            </div>
            <div className={styles.header_fields}>
              <MenuFilters.Root ref={menuFilterRef}>
                <MenuFilters.Item
                  id="type"
                  closeMenuRoot={handleCloseMenuByRef}
                >
                  <Controller
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <MenuTypeSelector
                        id="quick-filter-type"
                        value={field.value}
                        onChange={field.onChange}
                        enableOnlyTemplate={showOnlyTemplateOption}
                        enableShowOptionsListAndButtons={
                          isSelectedChannelEnabledToButtonsAndLists
                        }
                        enableShowOptionsTemplate={isSelectedChannelEnabledToSendTemplate}
                      />
                    )}
                  />
                </MenuFilters.Item>
                <MenuFilters.Item
                  id="department"
                  closeMenuRoot={handleCloseMenuByRef}
                >
                  <Controller
                    control={form.control}
                    name="department"
                    render={({ field }) => (
                      <MenuDepartmentSelector
                        id="quick-filter-department"
                        value={String(field.value ?? '')}
                        onChange={field.onChange}
                        role={userRole}
                      />
                    )}
                  />
                </MenuFilters.Item>
              </MenuFilters.Root>

              <Controller
                control={form.control}
                name="text"
                render={({ field }) => (
                  <QuickSearchTerm
                    id="quick-filter-name"
                    value={String(field.value ?? '')}
                    onChange={field.onChange}
                  />
                )}
              />

              {watchType === QuickTypes.QUICK && (
                <AddQuickMessageButton handleOpen={handleOpenEditModal} />
              )}
            </div>
          </div>

          {/* Caso ocorra erro ao carregar mensagens -> botão refazer a requisição */}
          {fetchFailure && (
            <div
              className={`${styles.list_container} ${styles.content_container}`}
            >
              <div>
                <Icon icon={'poli-icon pi-sad-fill'} size={20} />
                <small>Erro ao carregar mensagens</small>
              </div>
              <Button color="danger" onClick={reload}>
                Tentar novamente
              </Button>
            </div>
          )}

          {/* Loading */}
          {isLoading && (
            <div
              className={`${styles.list_container} ${styles.content_container}`}
            >
              <div>
                <Animation icon="loading" size="tiny" />
                <small>{'Buscando mensagens'}</small>
              </div>
            </div>
          )}

          {/* Lista de quick_messages filtrada por tipo e texto de busca */}
          {!isLoading && filteredQuickMessages?.length > 0 && (
            <div
              className={styles.list_container}
              onScroll={onScroll}
              ref={containerRef}
            >
              <ul>
                {filteredQuickMessages.map(
                  (message: QuickMessage, index: number) => (
                    <QuickItem.Root
                      key={`qm-${message.id}`}
                      id={`qm-${message.id}`}
                    >
                      <QuickItem.Description
                        handleClick={() => handleSendQuickMessage(message)}
                        // Cria um objeto com a referencia de cada item deste array
                        active={selectedItem === index}
                        ref={(ref) =>
                          (itemRef.current = {
                            ...itemRef.current,
                            [index]: ref,
                          })
                        }
                      >
                        <QuickItem.DescriptionHeader
                          name={message.tag}
                          shortcut={message.keyboard_shortcut ?? ''}
                        >
                          <QuickItem.DescriptionIcon messageType={watchType} />
                        </QuickItem.DescriptionHeader>
                        <QuickItem.DescriptionText
                          text={getMessageTextUI(message.text, watchType) ?? ''}
                        />
                      </QuickItem.Description>
                      <QuickItem.Controls>
                        <QuickItem.ControlPreview
                          handleView={() => {
                            handleOpenPreviewModal();
                            handleSetSelectedQuickMessage({
                              ...message,
                            });
                          }}
                        />
                        {watchType === QuickTypes.QUICK &&
                          isQuickMessageEditable(
                            message.user_id,
                            userRole,
                            userId
                          ) && (
                            <React.Fragment>
                              <QuickItem.ControlEdit
                                handleEdit={() => {
                                  handleOpenEditModal();
                                  handleSetSelectedQuickMessage(message);
                                }}
                              />
                              <QuickItem.ControlRemove
                                handleRemove={() => {
                                  handleOpenDeleteModal();
                                  handleSetSelectedQuickMessage(message);
                                }}
                              />
                            </React.Fragment>
                          )}
                      </QuickItem.Controls>
                    </QuickItem.Root>
                  )
                )}
              </ul>
            </div>
          )}

          {/* Busca vazia */}
          {!isLoading &&
            !fetchFailure &&
            filteredQuickMessages?.length == 0 && (
              <div className={styles.list_container}>
                <EmptyResult type={watchType} role={userRole} />
              </div>
            )}
        </div>
      )}

      <ModalQuickMessageForm
        isOpen={isOpenEdit}
        message={selectedQuickMessage}
        handleClose={handleCloseModal}
      />

      <ModalRemoveQuickMessage
        isOpen={isOpenDelete}
        message={selectedQuickMessage}
        handleClose={handleCloseModal}
      />

      <ModalMessagePreview
        isOpen={isOpenPreview}
        message={selectedQuickMessage}
        handleClose={handleCloseModal}
      />
    </React.Fragment>
  );
});
